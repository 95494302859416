export function parseCSV(csvData) {
  const rows = csvToArray(csvData);
  const result = [];
  let headers = rows[0];
  headers = headers.map(header => header.trim()); // ensure headers aren't padded with spaces
  const hasHeaders = headers.some(header => header.includes('Unit'));
  if (hasHeaders) {
    const rowsWithoutHeader = rows.slice(1, rows.length + 1);
    if (rowsWithoutHeader.length < 1) {
      return 'CSV file contains no record data';
    }
    else {
      for (let i = 0; i < rowsWithoutHeader.length; i++) {
        const obj = {};
        const currentRow = rowsWithoutHeader[i];
        if (currentRow.length > 0) { // MS Excel adds an empty row
          for (let j = 0; j < headers.length; j++) { // eslint-disable-line
            obj[headers[j]] = currentRow[j];
          }
          result.push(obj);
        }
      }
      result.forEach((result, index) => {
        result.key = index;
      });
      return result;
    }
    
  }
  else {
    return 'CSV file contains no headers';
  }
}

/* eslint-disable */
function csvToArray(text) {
  let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) {
          row[i] += l;
        }
        s = !s;
      } else if (',' === l && s) { 
        l = row[++i] = '';
      }
      else if ('\n' === l && s) {
        if ('\r' === p) {
          row[i] = row[i].slice(0, -1);
        }  
        row = ret[++r] = [l = ''];
        i = 0;
      } else {
        row[i] += l;
      }
      p = l;
  }
  return ret;
}
/* eslint-enable */