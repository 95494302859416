import React, { Component } from 'react';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import CreatePasswordForm from 'view/public/CreatePassword/CreatePasswordForm';

import styles from 'view/public/CreatePassword/index.module.css';

export class CreatePassword extends Component {

  render() {
    return (
      <React.Fragment>
        <div className={styles.logo}>
          <img src={ require('utils/media/logo.png') } alt='The Parking Tree' height='70px' />
        </div>
        <div className={styles.pageTitle}>Update Password</div>
        <SmartFormProvider>
          <CreatePasswordForm />
        </SmartFormProvider>
      </React.Fragment>
    );
  }

}

export default CreatePassword;