import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import { Modal } from '@timber-rider/parkingtree-client-common';

import { 
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@timber-rider/parkingtree-client-common';

import styles from 'view/private/Body/Enforcement/EnforcementEmployeeList/index.module.css';

const select = (state) => ({
  enforcementEmployees: state.enforcement.enforcementEmployees,
});

export class EnforcementEmployeeList extends Component {

  static propTypes = {
    enforcementEmployees: PropTypes.array.isRequired,
  };

  state = {
    isLoading: false,
    confirmDelete: false,
    deleteEmployeeId: undefined,
  };

  static contextType = SmartFormContext;

  render() {
    const { isLoading } = this.state;
    return (
      <div className={styles.pageContainer}>
        <div>
          <div className={styles.addEmployee}>
            <Button
              variant='contained'
              onClick={() => this.props.history.push('/enforcement/employeemanage')}
            >
              add employee
            </Button>
          </div>
          <SmartTable
            idKey='employeeId'
            rows={this.props.enforcementEmployees}
            title='Enforcement Employees'
            loading={isLoading}
            columns={[
              {
                key: 'fullName',
                title: 'Name',
                type: 'string',
              },
              {
                key: 'roleType',
                title: 'Role',
                type: 'string',
              },
            ]}
            actions={[
              {
                displayName: 'Edit Employee',
                type: 'menu',
                onSelect: row => this.props.history.push(`/enforcement/employeemanage/${row.employeeId}`),
              },
              {
                displayName: 'Delete Employee',
                type: 'menu',
                onSelect: row => {
                  this.setState(
                    { confirmDelete: true, deleteEmployeeId: (row.employeeId) }
                  );
                },
                showIf: () => true,
              },
            ]}
          >
            <TableToolbar />
            <TableContainer maxHeight='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
        {this.confirmDelete(this.state.deleteEmployeeId)}
      </div>
    );
  }

  confirmDelete(id) {
    const actionButtons = [
      {
        label: 'OK',
        action: () => {
          this.setState(
            { confirmDelete: false },
            () => this.deleteEmployee(id)
          );
        },
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDelete: false })}
        show={this.state.confirmDelete}
        title='Confirm Delete'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          Are you sure you want to delete this employee?
        </div>
      </Modal>
    );
  }

  deleteEmployee(id) {
    alert(`The id = ${id}`);
  }

}

export default withRouter(connect(select, null)(EnforcementEmployeeList));