import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  toggleLoaderOn,
  toggleLoaderOff,
  userLogin,
} from 'view/actions';

import { addAlert } from 'utils/components/alerts/actions';

import { Button } from '@mui/material';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import EmailInput from 'utils/SmartForm/components/EmailInput';
import PasswordInput from 'utils/SmartForm/components/PasswordInput';

import styles from 'view/public/Login/LoginForm/index.module.css';

export class LoginForm extends Component {
  
  static propTypes = {
    userLogin: PropTypes.func.isRequired,
    toggleLoaderOn: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
  };

  static contextType = SmartFormContext;

  render() {
    return (
      <form 
        onSubmit={this.loginUser}
        className={styles.container}
      > 
        <div className={styles.container}>
          <div className={styles.email}>
            <EmailInput
              inputName='email'
              isRequired={true}
              labelText='Email'
              style={{ width: '240px', height: '75px' }}
              inputFocus={true}
            />
          </div>
          <div className={styles.password}>
            <PasswordInput
              inputName='pass'
              isRequired={true}
              labelText='Password'
              style={{ width: '240px', height: '75px' }}
            />
          </div>
          <div className={styles.btns}>
            <Button
              type='submit'
              variant='contained'
              style={{ width: '250px' }}
              onClick={e => this.loginUser(e)}
            >
              Login
            </Button>
          </div>
        </div>
      </form>
    );
  }

  loginUser(e) {
    e.preventDefault();
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const apiFormattedObj = this.context.prepInputList(validObj.inputList);
      this.props.toggleLoaderOn();
      this.props.userLogin(apiFormattedObj)
        .then(response => {
          response.payload.data.hasOTP
            ? this.props.history.push('/create-password')
            : response.status === 400
              ? this.props.addAlert('error', 'User credentials invalid')
              : null;
        })
        .catch(error => {
          console.log(JSON.stringify(error)); // eslint-disable-line
        })
        .finally(() => {
          this.props.toggleLoaderOff();
        });
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }
}

export default withRouter(connect(null, { 
  userLogin,
  toggleLoaderOn,
  toggleLoaderOff,
  addAlert,
})(LoginForm));