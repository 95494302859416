import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import DropdownInput from 'utils/SmartForm/components/DropdownIinput';
import EmailInput from 'utils/SmartForm/components/EmailInput';
import PhoneInput from 'utils/SmartForm/components/PhoneInput';
import TextInput from 'utils/SmartForm/components/TextInput';

import styles from 'view/private/Body/Property/PropertyManageEmployee/PropertyManageEmployeeForm/index.module.css';

const towEmployeeTypes = [
  { id: 0, name: 'Manager' },
  { id: 1, name: 'Staff' }, 
];

export class PropertyManageEmployeeForm extends Component {

  static contextType = SmartFormContext;

  render() {
    const { id } = this.props.match.params;
    const pageLabel = id ? 'edit employee' : 'add employee';
    return (
      <React.Fragment>
        <div className={styles.firstName}>
          <TextInput
            inputName='FirstName'
            isRequired={true}
            labelText='First Name'
            style={{ width: '240px' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.lastName}>
          <TextInput
            inputName='LastName'
            isRequired={true}
            labelText='Last Name'
            style={{ width: '240px' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.role}>
          <DropdownInput
            inputName='roleType'
            isRequired={true}
            labelText='Role'
            style={{ width: '240px' }}
            options={towEmployeeTypes.map(employee => ({
              value: employee.id,
              display: employee.name,
            }))}
          />
        </div>
        <div className={styles.phone}>
          <PhoneInput
            inputName='phone'
            isRequired={true}
            labelText='Phone'
            style={{ width: '240px' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.email}>
          <EmailInput
            inputName='EmailAddress'
            isRequired={true}
            labelText='Email'
            style={{ width: '505px' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.button}>
          <Button
            onClick={() => this.props.history.goBack()}
            style={{ width: '175px' }}
          >
            cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            onClick={() => this.manageEmployee()}
            style={{ width: '175px' }}
          >
            {pageLabel}
          </Button>
        </div>
      </React.Fragment>
    );
  }

  manageEmployee() {
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);
    if (validationObject.isValid) {
      this.context.prepInputList(validationObject.inputList);
      const inputs = this.context.prepInputList(validationObject.inputList);
      alert(JSON.stringify(inputs));
      ///this.props.history.goBack();
    }
    else {
      this.context.updateInputList(validationObject.inputList);
      this.forceUpdate();
    }
  }

}

export default withRouter(connect(null, null)(PropertyManageEmployeeForm));