import React, { Component } from 'react';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';

import LoginForm from 'view/public/Login/LoginForm';

import styles from 'view/public/Login/index.module.css';

export class Login extends Component {
  
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={ require('utils/media/logo.png') } alt='The Parking Tree' height='70px' />
        </div>
        <div className={styles.form}>
          <SmartFormProvider>
            <LoginForm />
          </SmartFormProvider>
        </div>
      </div>
    );
  }
}

export default Login;