import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Drawer,
  List,
  ListItemText,
  ListItemIcon,
  Tooltip,
  ListItemButton,
} from '@mui/material';

import { withStyles } from '@mui/styles';

import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ViewListIcon from '@mui/icons-material/ViewList';
import HandymanIcon from '@mui/icons-material/Handyman';

import {
  toggleSidenav,
  userLogout,
  toggleLoaderOff
} from 'view/actions';

import { addAlert } from 'utils/components/alerts/actions';

import styles from 'view/private/Sidenav/index.module.css';

const select = (state) => ({
  isAuthenticated: state.session.isAuthenticated,
  sideNavOpen: state.session.sideNavOpen,
  disableLeftNavigationMenu: state.session.disableLeftNavigationMenu,
});

const muiStyles = {
  sidenavOpen: {
    height: '100%',
    overflowX: 'visible',
    transition: 'all .5s ease',
    width: '200px',
  },
  sidenavMini: {
    height: '100%',
    overflowX: 'hidden',
    transition: 'width .5s ease',
  },
};

export class Sidenav extends Component {

  static propTypes = {
    addAlert: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    toggleSidenav: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    sideNavOpen: PropTypes.bool,
  };

  state = {
    menuSelection: {
      taskqueue: false,
      properties: false,
      enforcement: false,
      staff: false,
      dashboard: false,
      utilities: false,
      settings: false,
    },
  };

  render() {
    const { isAuthenticated, sideNavOpen } = this.props;
    return (
      isAuthenticated &&
      <div>
        <Drawer
          open={sideNavOpen}
          PaperProps={{ style: sideNavOpen ? muiStyles.sidenavOpen : muiStyles.sidenavMini }}
          id='drawer'
          variant={isAuthenticated ? 'permanent' : 'persistent'} // ensures drawer closes when logged out
        >
          {this.renderSidenavContents()}
        </Drawer>
      </div>
    );
  }

  selectListItem(item) {
    const { menuSelection } = this.state;
    Object.keys(menuSelection).forEach(item => menuSelection[item] = false);
    menuSelection[item] = true;
    this.setState({ menuSelection });
  }

  renderSidenavContents = () => {
    const { menuSelection } = this.state;
    return (
      <React.Fragment>
        <div className={styles.sideNavContainer}>
          <List style={{ padding: '0' }}>

            <ListItemButton
              onClick={() => {
                this.selectListItem('taskqueue');
                this.props.history.push('/');
              }}
              button='true' 
              selected={menuSelection.taskqueue}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='Task Queue'
                  placement='right'
                >
                  <ViewListIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='Task Queue' />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                this.selectListItem('properties');
                this.props.history.push('/property-management/list');
              }}
              button='true'  
              selected={menuSelection.properties}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='Properties'
                  placement='right'
                >
                  <EmojiTransportationIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='Properties' style={{ color: '#060606' }} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                this.selectListItem('enforcement');
                this.props.history.push('/enforcement-list');
              }}
              button='true'  
              selected={menuSelection.enforcement}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='Enforcement'
                  placement='right'
                >
                  <LocalShippingIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='Enforcement' style={{ color: '#060606' }} />
            </ListItemButton>

            <ListItemButton
              onClick={() => { 
                this.selectListItem('staff');
                this.props.history.push('/staff/stafflist');
              }}
              button='true' 
              selected={menuSelection.staff}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='Staff'
                  placement='right'
                >
                  <PeopleIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='Staff' style={{ color: '#060606' }} />
            </ListItemButton>

            <ListItemButton
              onClick={() => { 
                this.selectListItem('dashboard');
                this.props.history.push('/dashboard');
              }}
              button='true'  
              selected={menuSelection.dashboard}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='Dashboard'
                  placement='right'
                >
                  <BarChartIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItemButton>

            <ListItemButton
              onClick={() => { 
                this.selectListItem('utilities');
                this.props.history.push('/utilities');
              }}
              button='true' 
              selected={menuSelection.utilities}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='Utilities'
                  placement='right'
                >
                  <HandymanIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='Utilities' />
            </ListItemButton>

          </List>
  
        </div>

        <div className={styles.bottomMenu}>

          <List style={{ padding: '0' }}>

            <ListItemButton
              onClick={() => { 
                this.selectListItem('settings');
                this.props.history.push('/settings');
              }}
              button='true' 
              selected={menuSelection.settings}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='My Settings'
                  placement='right'
                >
                  <SettingsIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='My Settings' style={{ color: '#060606' }} />
            </ListItemButton>

            <ListItemButton
              onClick={() => { 
                this.props.userLogout();
                this.props.addAlert('success', 'You have successfully logged out.');
                this.props.history.push('/');
              }}
              button='true' 
              selected={false}
            >
              <ListItemIcon style={{ minWidth: '35px' }}>
                <Tooltip
                  title='Log Out'
                  placement='right'
                >
                  <PowerSettingsNewIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary='Log Out' style={{ color: '#060606' }} />
            </ListItemButton>

          </List>
        </div>
      </React.Fragment>
    );
  };
}

export default withRouter(withStyles(muiStyles)(connect(select, {
  toggleSidenav,
  toggleLoaderOff,
  userLogout,
  addAlert,
})(Sidenav)));