import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import TextInput from 'utils/SmartForm/components/TextInput';
import DropdownInput from 'utils/SmartForm/components/DropdownIinput';
import EmailInput from 'utils/SmartForm/components/EmailInput';
import PhoneInput from 'utils/SmartForm/components/PhoneInput';

import { addAlert } from 'utils/components/alerts/actions';
import { toggleLoaderOn, toggleLoaderOff } from 'view/actions';
import { getUser, getRoles, createUser, updateUser } from 'view/private/Body/Staff/actions';

import styles from 'view/private/Body/Staff/StaffManage/StaffManageForm/index.module.css';


const select = (state) => ({
  roles: state.staff.roles,
  user: state.staff.user,
});

export class StaffManageForm extends Component {

  static propTypes = {
    getUser: PropTypes.func.isRequired,
    getRoles: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    addAlert: PropTypes.func.isRequired,
    toggleLoaderOn: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const apiCalls = [this.props.getRoles()];    
    const { id } = this.props.match.params;
    if (id) {
      apiCalls.push(this.props.getUser(id));
    }
    this.props.toggleLoaderOn();
    Promise.all(apiCalls)
      .then(() => {
        if (id) {
          const inputList = cloneDeep(this.context.inputList);
          inputList.forEach(input => {
            for (const [key, value] of Object.entries(this.props.user)) {
              if (input.inputName === key) {
                input.inputValue = value;
              }
            }
          });
          this.context.updateInputList(inputList);
        }
      })
      .catch(error => {
        console.log(JSON.stringify(error)) // eslint-disable-line
      })
      .finally(() => {
        this.props.toggleLoaderOff();
      });
  }

  static contextType = SmartFormContext;

  render() {
    const { roles } = this.props;
    const { id } = this.props.match.params;
    return (
      <React.Fragment>
        <div className={styles.firstName}>
          <TextInput
            inputName='first_name'
            isRequired={true}
            labelText='First Name'
            style={{ width: '240px' }}
            autoComplete={'off'}
            inputFocus={true}
          />
        </div>
        <div className={styles.lastName}>
          <TextInput
            inputName='last_name'
            isRequired={true}
            labelText='Last Name'
            style={{ width: '240px' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.role}>
          <DropdownInput
            inputName='role_id'
            isRequired={true}
            labelText='Role'
            style={{ width: '240px' }}
            options={roles.map(role => ({
              value: role.role_id,
              display: role.role_name,
            }))}
          />
        </div>
        <div className={styles.phone}>
          <PhoneInput
            inputName='mobile_phone'
            isRequired={true}
            labelText='Phone'
            style={{ width: '240px' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.email}>
          <EmailInput
            inputName='email'
            isRequired={true}
            labelText='Email'
            style={{ width: '505px' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.button}>
          <Button
            onClick={() => this.props.history.push('/staff/stafflist')}
            style={{ width: '175px' }}
          >
            cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            onClick={() => id ? this.updateUser() : this.createUser()}
            style={{ width: '175px' }}
          >
            {id ? 'edit staff' : 'add staff'}
          </Button>
        </div>
      </React.Fragment>
    );
  }

  createUser() {
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);
    if (validationObject.isValid) {
      const apiData = this.context.prepInputList(validationObject.inputList);
      apiData.organization_id = 0; // parkingtree orgId is always zero
      this.props.toggleLoaderOn();
      this.props.createUser(apiData)
        .then(() => {
          this.props.addAlert('success', 'User added.');
          this.props.history.push('/staff/stafflist');
        })
        .catch(error => {
          console.log(JSON.stringify(error));  // eslint-disable-line
        })
        .finally(() => {
          this.props.toggleLoaderOff();
        });
    }
    else {
      this.context.updateInputList(validationObject.inputList);
    }
  }

  updateUser() {
    const { id } = this.props.match.params;
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);
    if (validationObject.isValid) {
      const apiData = this.context.prepInputList(validationObject.inputList);
      apiData.organization_id = 0; // parkingtree orgId is always zero
      apiData.user_id = Number(id);
      apiData.role_id = Number(apiData.role_id);
      this.props.toggleLoaderOn();
      this.props.updateUser(apiData)
        .then(() => {
          this.props.addAlert('success', 'User updated.');
          this.props.history.push('/staff/stafflist');
        })
        .catch(error => {
          console.log(JSON.stringify(error)); // eslint-disable-line
        })
        .finally(() => {
          this.props.toggleLoaderOff();
        });
    }
    else {
      this.context.updateInputList(validationObject.inputList);
    }
  }

}

export default withRouter(connect(select, {
  getUser,
  getRoles,
  createUser,
  updateUser,
  addAlert,
  toggleLoaderOn,
  toggleLoaderOff,
})(StaffManageForm));