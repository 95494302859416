import React, { Component } from 'react';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import StaffManageForm from 'view/private/Body/Staff/StaffManage/StaffManageForm/index';

import styles from 'view/private/Body/Staff/StaffManage/index.module.css';

export class StaffManage extends Component {

  render() {
    const { id } = this.props.match.params;
    const pageLabel = id ? 'edit staff' : 'add staff';
    return (
      <div className={styles.container}>
        <div className={styles.title}>{pageLabel}</div>
        <SmartFormProvider>
          <StaffManageForm />
        </SmartFormProvider>
      </div>
    );
  }
}

export default StaffManage;