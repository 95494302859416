import React, { Component } from 'react';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import PropertyManageEnforcementForm from 'view/private/Body/Property/PropertyManageEnforcement/PropertyManageEnforcementForm/index';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

import styles from 'view/private/Body/Property/PropertyManageEnforcement/index.module.css';


export class PropertyManageEnforcement extends Component {
  
  state = {
    loading: false,
  };
  
  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay
        show={loading}
        width='100%'
      >
        <div className={styles.pageTitle}>
          X Property Enforcement Companies
        </div>
        <SmartFormProvider> 
          <PropertyManageEnforcementForm /> 
        </SmartFormProvider>
      </LoadingOverlay>
    );
  }
}

  
export default PropertyManageEnforcement;