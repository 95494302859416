import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Route } from 'react-router-dom';

import Sidenav from 'view/private/Sidenav';

import TaskQueue from 'view/private/Body/TaskQueue';
import EnforcementList from 'view/private/Body/Enforcement/EnforcementList';
import DashBoard from 'view/private/Body/Dashboard';
import Settings from 'view/private/Body/Settings';
import Utilities from 'view/private/Body/Utilities';
import PropertyList from 'view/private/Body/Property/PropertyList/index';
import StaffList from 'view/private/Body/Staff/StaffList/index';
import RolesList from 'view/private/Body/Staff/RolesList/index';
import RolesManage from 'view/private/Body/Staff/RolesManage/index';
import StaffManage from 'view/private/Body/Staff/StaffManage/index';
import EnforcementManage from 'view/private/Body/Enforcement/EnforcementManage/index';
import EnforcementEmployeeManage from 'view/private/Body/Enforcement/EnforcementEmployeeManage/index';
import PropertyEnforcement from 'view/private/Body/Property/PropertyEnforcement/index';
import PropertyManageEnforcement from 'view/private/Body/Property/PropertyManageEnforcement/index';
import PropertyEmployee from 'view/private/Body/Property/PropertyEmployee/index';
import PropertyManageEmployee from 'view/private/Body/Property/PropertyManageEmployee/index';
import EnforcementEmployeeList from 'view/private/Body/Enforcement/EnforcementEmployeeList/index';
import PropertyCreate from 'view/private/Body/Property/PropertyCreate/index';

import styles from 'view/private/index.module.css';

export class Private extends Component {

  static propTypes = {
    isLoader: PropTypes.bool.isRequired,
  };

  render() {
    const pointerEvents = this.props.isLoader ? 'none' : 'auto';
    return (
      <div className={styles.container} style={{ pointerEvents }}>
        <div className={styles.sidNav}><Sidenav /></div>
        <div className={styles.body}>
          <img src={ require('utils/media/logo.png') } alt='The Parking Tree' height='70px' />
          <Switch>
            <Route exact path='/' render={props => (<TaskQueue {...props} />)} />
            <Route exact path='/enforcement-list' render={props => (<EnforcementList {...props} />)} />
            <Route exact path='/dashboard' render={props => (<DashBoard {...props} />)} />
            <Route exact path='/settings' render={props => (<Settings {...props} />)} />
            <Route exact path='/utilities' render={props => (<Utilities {...props} />)} />
            <Route exact path='/property-management/list' render={props => (<PropertyList {...props} />)} />
            <Route exact path='/staff/stafflist' render={props => (<StaffList {...props} />)} />
            <Route exact path='/staff/roleslist' render={props => (<RolesList {...props} />)} />
            <Route exact path='/staff/rolesmanage' render={props => (<RolesManage {...props} />)} />
            <Route exact path='/staff/rolesmanage/:id' render={props => (<RolesManage {...props} />)} />
            <Route exact path='/staff/staffmanage' render={props => (<StaffManage {...props} />)} />
            <Route exact path='/staff/staffmanage/:id' render={props => (<StaffManage {...props} />)} />
            <Route exact path='/enforcement/enforcementemployeelist/:id' render={props => (<EnforcementEmployeeList {...props} />)} />
            <Route exact path='/enforcement/enforcementmanage' render={props => (<EnforcementManage {...props} />)} />
            <Route exact path='/enforcement/enforcementmanage/:id' render={props => (<EnforcementManage {...props} />)} />
            <Route exact path='/enforcement/employeemanage' render={props => (<EnforcementEmployeeManage {...props} />)} />
            <Route exact path='/enforcement/employeemanage/:id' render={props => (<EnforcementEmployeeManage {...props} />)} />
            <Route exact path='/property-management/propertyenforcement/manageenforcement/:id' render={props => (<PropertyManageEnforcement {...props} />)} />
            <Route exact path='/property-management/employee/:id' render={props => (<PropertyEmployee {...props} />)} />
            <Route exact path='/property-management/enforcement/:id' render={props => (<PropertyEnforcement {...props} />)} />
            <Route exact path='/property-management/employee/manageemployee/addemployee' render={props => (<PropertyManageEmployee {...props} />)} />
            <Route exact path='/property-management/employee/manageemployee/:id' render={props => (<PropertyManageEmployee {...props} />)} />
            <Route exact path='/property-management/create' render={props => (<PropertyCreate {...props} />)} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Private);