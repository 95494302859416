import { cloneDeep } from 'lodash';
import {
  LOGIN,
  BROWSER_WARNING,
  MOBILE_WARNING,
  TOGGLE_LOADER_ON,
  TOGGLE_LOADER_OFF,
  CONFIRM_OTP,
  CREATE_PASSWORD,
} from './constants';

const initialState = {
  isAuthenticated: false,
  isLoader: false,
  token: '',
  userDetails: {},
  errors: [],
  permissions: {},  
  disableLeftNavigationMenu: false,
  sideNavOpen: false,
  otp: '',
};

export default function Session(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    
    case CREATE_PASSWORD:
    case LOGIN: { 
      try {
        const { email, first_name, last_name, mobile_phone, role_name, token } = action.payload.data;
        newState.token = token;
        newState.isAuthenticated = token !== undefined;
        newState.userDetails.name = `${first_name} ${last_name}`;
        newState.userDetails.email = email;
        newState.userDetails.mobile_phone = mobile_phone;
        newState.userDetails.role = role_name;
        newState.permissions = action.payload.data.permissions;
      }
      catch (error) {
        console.log(JSON.stringify(error)); // eslint-disable-line
      }      
      return newState;
    }

    case CONFIRM_OTP: {
      newState.otp = action.meta;
      return newState;
    }

    case BROWSER_WARNING: {
      newState.showBrowserWarning = !state.showBrowserWarning;
      return newState;
    }

    case MOBILE_WARNING: {
      newState.showMobileWarning = !state.showMobileWarning;
      return newState;
    }

    case TOGGLE_LOADER_ON: {
      newState.isLoader = true;
      return newState;
    }

    case TOGGLE_LOADER_OFF: {
      newState.isLoader = false;
      return newState;
    }

    default: 
      return newState;
  }
}