import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styles from 'view/private/Body/Property/PropertyManageEnforcement/PropertyManageEnforcementForm/index.module.css';


export class PropertyManageEnforcementForm extends Component {

  static propTypes = {
    // roleList: PropTypes.array.isRequired,
  };

  state = {
    unusedEnforcement:[
      'AAA Towing',
      'Triple AAA Towing',
      'Lift Towing',
      'Autocare Towing',
    ],
    usedEnforcement: [],
  };

  static contextType = SmartFormContext;

  render() {
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'grid', marginRight: '20px', marginTop: '5px', backgroundColor: '#EEE', borderRadius: '10px', padding: '10px', alignContent: 'start', width: '300px', minHeight: '500px' }}>
            <div className={styles.unused}>Unassigned Enforcement Companies</div>
            {this.renderUnused()}
          </div>
          <div style={{ display: 'grid', marginTop: '5px', backgroundColor: '#EEE', borderRadius: '5px', padding: '10px', alignContent: 'start', width: '300px' }}>
            <div className={styles.used}>Assigned Enforcement Companies</div>
            {this.renderUsed()}
          </div>
        </div>
        <div style={{ marginTop: '15px' }}>
          <Button
            onClick={() => this.props.history.goBack()}
            style={{ width: '175px' }}
          >
            cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            onClick={() => this.manageRole()}
            style={{ width: '175px' }}
          >
            save changes
          </Button>
        </div>
      </React.Fragment>
    );
  }

  manageRole() {
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);
    if (validationObject.isValid) {
      const result = this.context.prepInputList(validationObject.inputList);
      result.unusedEnforcement = this.state.unusedEnforcement;
      result.usedEnforcement = this.state.usedEnforcement;
      alert(JSON.stringify(result));
      //this.props.history.goBack();
    }
    else {
      this.context.updateInputList(validationObject.inputList);
      this.forceUpdate();
    }
  }

  renderUsed() {
    const usedEnforcement = cloneDeep(this.state.usedEnforcement);
    if (usedEnforcement.length === 0) {
      return (
        <div id='claims-unused' style={{ textAlign: 'center', marginTop: '10px' }}>
          No Enforcement companies added yet.<br />You must add at least one Enforcement company.
        </div>
      );
    }
    
    return usedEnforcement.map((claim, index) => {
      return (
        <div onClick={() => this.handleRemove(index)} className={styles.addUser} key={index}>
          <ArrowBackIcon />
          <span style={{ width: '100%', textAlign: 'right' }}>
            {claim}
          </span>
        </div>
      );
    });
  }

  renderUnused() {
    const unusedEnforcement = cloneDeep(this.state.unusedEnforcement);
    if (unusedEnforcement.length === 0) {
      return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          All Enforcement companies are associated.
        </div>
      );
    }    
    
    return unusedEnforcement.map((claim, index) => {
      return (
        <div onClick={() => this.handleAdd(index)} className={styles.addUser} key={index}>
          <span style={{ width: '100%' }}>
            {claim}
          </span>
          <ArrowForwardIcon />
        </div>
      );
    });
  }

  handleAdd(id) {
    const { usedEnforcement, unusedEnforcement } = cloneDeep(this.state);
    usedEnforcement.push(unusedEnforcement[id]);
    unusedEnforcement.splice(id, 1);
    this.setState({ unusedEnforcement, usedEnforcement });
  }

  handleRemove(id) {
    const { usedEnforcement, unusedEnforcement } = cloneDeep(this.state);
    unusedEnforcement.push(usedEnforcement[id]);
    usedEnforcement.splice(id, 1);
    this.setState({ unusedEnforcement, usedEnforcement });
  }

}

export default withRouter(connect(null, null)(PropertyManageEnforcementForm));