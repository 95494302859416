import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import EditUnitForm from 'utils/SmartForm/groups/PropertyUnitsGroup/EditUnit/EditUnitForm/index';

//import styles from 'utils/SmartForm/groups/PropertyUnitsGroup/index.module.css';

export class EditUnit extends Component {

  static propTypes = {
    editUnit: PropTypes.func.isRequired,
    isEditingUnit: PropTypes.bool.isRequired,
    closeEditUnit: PropTypes.func.isRequired,
    currentUnit: PropTypes.object.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };

  state = {
    isEditingUnit: this.props.isEditingUnit,
    currentUnit: this.props.currentUnit,
    propertyObj: this.props.propertyObj,
  };

  render() {
    const { isEditingUnit, currentUnit, propertyObj } = this.state;
    return (
      <SmartFormProvider> 
        <EditUnitForm 
          editUnit={this.props.editUnit}
          isEditingUnit={isEditingUnit}
          closeEditUnit={this.props.closeEditUnit}
          currentUnit={currentUnit}
          propertyObj={propertyObj}
        /> 
      </SmartFormProvider>
    );
  }
}

  
export default withRouter(connect(null, null)(EditUnit));
