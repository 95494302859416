import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';

import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styles from 'utils/SmartForm/groups/PropertyEnfrocementAssociationGroup/index.module.css';

export class PropertyEnfrocementAssociationGroup extends Component {

  static propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    buildProperty: PropTypes.func.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };

  state = {
    isLloading: false,
    isFormBuilt: false,
    unusedTows:[
      { id: 0, name: 'AAA Towing' },
      { id: 1, name: 'Triple AAA Towing' },
      { id: 2, name: 'Lift Towing' },
      { id: 3, name: 'Autocare Towing' },
    ],
    usedTows: [],
    validationMsg: '',
  };

  componentDidMount() {
    const usedTows = cloneDeep(this.props.propertyObj.Towing);
    if (!this.state.isFormBuilt && !isEmpty(usedTows)) {
      let unusedTows;
      usedTows.forEach(usedTow => unusedTows = this.state.unusedTows.filter(unused => usedTow.id !== unused.id));
      this.setState({ isFormBuilt: true, unusedTows, usedTows });
    }
  }

  render() {
    const { validationMsg } = this.state;
    const { PropertyInfo } = this.props.propertyObj;
    return (
      <div>
        <div className={styles.pageTitle} style={{ textAlign: 'center' }}>Enforcement Companies</div>
        <div style={{ textAlign: 'center', color: 'red', padding: '15px' }}>{validationMsg}&nbsp;</div>
        <div style={{ display: 'flex', margin: 'auto' }}>
          <div style={{ display: 'grid', marginRight: '20px', marginTop: '5px', backgroundColor: '#EEE', borderRadius: '10px', padding: '10px', alignContent: 'start', minHeight: '500px' }}>
            <div className={styles.unused}>
              Unassigned Enforcement Companies<br />
              in the {PropertyInfo.MailingPostalCode} area
            </div>
            {this.renderUnused()}
          </div>
          <div style={{ display: 'grid', marginTop: '5px', backgroundColor: '#EEE', borderRadius: '5px', padding: '10px', alignContent: 'start', }}>
            <div className={styles.used}>
              Assigned Enforcement Companies<br />
              in the {PropertyInfo.MailingPostalCode} area
            </div>
            {this.renderUsed()}
          </div>
        </div>
        <div style={{ marginTop: '15px', textAlign: 'center', paddingBottom: '10px' }}>
          <Button
            variant='contained'
            onClick={() => this.props.prevStep()}
          >
            previous
          </Button>&nbsp;
          <Button
            variant='contained'
            onClick={() => this.addTowing()}
          >
            next
          </Button>
        </div>
      </div>
    );
  }

  addTowing() {
    const { usedTows } = this.state;
    if (usedTows && usedTows.length > 0) {
      const propertyObj = cloneDeep(this.props.propertyObj);
      propertyObj.Towing = usedTows;
      this.props.buildProperty(propertyObj);
      this.props.nextStep();
    }
    else {
      this.setState({ validationMsg: 'You must add at least one towing company.' });
    }
  }
  
  renderUsed() {
    const usedTows = cloneDeep(this.state.usedTows);
    if (usedTows.length === 0) {
      return (
        <div id='claims-unused' style={{ textAlign: 'center', marginTop: '10px' }}>
          No tow companies added yet.<br />You must add at least one tow company.
        </div>
      );
    }
    
    return usedTows.map((tow, index) => {
      return (
        <div onClick={() => this.handleRemove(index)} className={styles.addUser} key={index}>
          <ArrowBackIcon />
          <span style={{ width: '100%', textAlign: 'right' }}>
            {tow.name}
          </span>
        </div>
      );
    });
  }

  renderUnused() {
    const unusedTows = cloneDeep(this.state.unusedTows);
    if (unusedTows.length === 0) {
      return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          All tow companies are associated.
        </div>
      );
    }    
    
    return unusedTows.map((tow, index) => {
      return (
        <div onClick={() => this.handleAdd(index)} className={styles.addUser} key={index}>
          <span style={{ width: '100%' }}>
            {tow.name}
          </span>
          <ArrowForwardIcon />
        </div>
      );
    });
  }

  handleAdd(id) {
    const { usedTows, unusedTows } = cloneDeep(this.state);
    usedTows.push(unusedTows[id]);
    unusedTows.splice(id, 1);
    this.setState({ unusedTows, usedTows });
  }

  handleRemove(id) {
    const { usedTows, unusedTows } = cloneDeep(this.state);
    unusedTows.push(usedTows[id]);
    usedTows.splice(id, 1);
    this.setState({ unusedTows, usedTows });
  }

}

export default connect(null, null)(PropertyEnfrocementAssociationGroup);