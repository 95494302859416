import React, { Component } from 'react';

import CustomerSupportModal from 'view/private/Body/TaskQueue/CustomerSupportModal/index';

import {
  SmartTable,
  TableContainer,
  TableHeader,
  TableToolbar,
  TablePagination,
  TableRows,
} from '@timber-rider/parkingtree-client-common';

import TaskQueueTableRow from 'view/private/Body/TaskQueue/TaskQueueTableRow';

import styles from 'view/private/Body/TaskQueue/index.module.css';

const columns = [
  {
    key: 'placeHolderColumn',
    title: '',
    type: 'string',
    customStyle: { width: '40px', padding: '10px 0' },
  },
  {
    key: 'TaskName',
    title: 'Task',
    type: 'string',
  },
  {
    key: 'Status',
    title: 'Status',
    type: 'string',
  },
  {
    key: 'StaffName',
    title: 'Staff',
    type: 'string',
  },
  {
    key: 'DateModified',
    title: 'Date Modified',
    type: 'dateTime',
  },
];

export class TaskQueue extends Component {

  state = {
    isLoading: false,
    isCustomerSupport: false,
    taskQueueList: [
      {
        TaskQueueId: 2854,
        Status: 'Needs Approval',
        TaskName: 'Create Property',
        TaskType: 'CreateProperty',
        Url: '/2854',
        StaffId: 158825,
        StaffName: 'Heber Anderson',
        DateModified: '9/08/2023 09:02 AM',
        Duration: 14400,
      },
      {
        TaskQueueId: 2855,
        Status: 'Needs Response',
        TaskName: 'Customer Support',
        TaskType: 'CustomerSupport',
        Url: '/2856',
        StaffId: 158824,
        StaffName: 'Lauren Thompson',
        DateModified: '09/08/2023 9:00 AM',
        Duration: 1800,
      },
      {
        TaskQueueId: 2856,
        Status: 'Needs Approval',
        TaskName: 'Deactivate Property',
        TaskType: 'DeactivateProperty',
        Url: '/2857',
        StaffId: 158834,
        StaffName: 'Kristi Amberson',
        DateModified: '9/10/2023 10:02 AM',
        Duration: 14400,
      },
      {
        TaskQueueId: 2857,
        Status: 'Needs Approval',
        TaskName: 'Add Enforcement Company',
        TaskType: 'AddEnforcementCompany',
        Url: '/2859',
        StaffId: 158734,
        StaffName: 'Bruce Amberson',
        DateModified: '9/11/2022 11:02 AM',
        Duration: 14400,
      }
    ],
    claims: {
      POA: true,
    }
  };
  
  render() {
    const { isLoading, taskQueueList } = this.state;
    const sortedIcon = <span className={styles.header_dot} />;
    return (
      <div className={styles.container}>
        <SmartTable
          idKey='TaskQueueId'
          columns={columns}
          rows={taskQueueList}
          emptyMessage='No tasks at this time.'
          loading={isLoading}
          tableSettings={{ sortColumn: 'DateModified', sortDirection: 'asc', rowsPerPage: 25 }}
          title='Task Queue'
        >
          <TableToolbar />
          <TableContainer maxHeight='1000px'>
            <TableHeader customSortedIcon={sortedIcon} />
            <TableRows
              CustomRow={TaskQueueTableRow}
              CustomRowProps={{ taskAction: this.taskAction }}
            />
          </TableContainer>
          <TablePagination />
        </SmartTable>
        <CustomerSupportModal 
          show={this.state.isCustomerSupport}
          onClose={() => this.setState({ isCustomerSupport: false })}
        />
      </div>
    );
  }

  taskAction = curTask => {
    const { claims } = this.state;
    if (curTask) {
      switch (curTask.TaskType) {
        case 'CreateProperty':
          if (claims.POA) {
            alert('Its working!');
          }
          break;
        case 'CustomerSupport':
          this.setState({ isCustomerSupport: true });
          break;
        default:
        // code block 
      }
    }
    else {
      return 'invalid task type';
    }
  };

}

export default TaskQueue;