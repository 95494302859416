import { GETrequest, POSTrequest, PUTrequest } from 'utils/helpers/api_handler';
import {
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  GET_ROLES,
  GET_ROLES_PERMISSIONS,
  GET_PERMISSIONS,
  CREATE_ROLE,
  UPDATE_ROLE,
  GET_USERS_BY_ORG,
} from 'view/private/Body/Staff/constants';

export function getUser(id) {
  const payload = GETrequest(`/user/${id}`);
  return {
    type: GET_USER,
    payload,
  };
}

export function createUser(body) {
  const payload = POSTrequest('/user/create', body);
  return {
    type: CREATE_USER,
    payload,
  };
}

export function updateUser(body) {
  const payload = PUTrequest('/user/update', body);
  return {
    type: UPDATE_USER,
    payload,
  };
}

export function getRoles() {
  const payload = GETrequest('/user/roles/all');
  return {
    type: GET_ROLES,
    payload,
  };
}

export function getRolesPermissions(id) {
  const payload = GETrequest(`/user/role/permissions/${id}`);
  return {
    type: GET_ROLES_PERMISSIONS,
    payload,
  };
}

export function getUsersByOrganization(id) {
  const payload = GETrequest(`/user/organization/${id}`);
  return {
    type: GET_USERS_BY_ORG,
    payload,
  };
}

export function getPermissions() {
  const payload = GETrequest('/user/permissions');
  return {
    type: GET_PERMISSIONS,
    payload,
  };
}

export function createRole(body) {
  const payload = POSTrequest('/user/role/create', body);
  return {
    type: CREATE_ROLE,
    payload,
  };
}

export function updateRole(body) {
  const payload = PUTrequest('/user/role/update', body);
  return {
    type: UPDATE_ROLE,
    payload,
  };
}