import React, { Component } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { 
  Stepper,
  Step,
  StepButton,
} from '@mui/material';

import { Card } from '@timber-rider/parkingtree-client-common';

import PropertyAddressGroup from 'utils/SmartForm/groups/PropertyInfoGroup';
import PropertyEnfrocementAssociationGroup from 'utils/SmartForm/groups/PropertyEnfrocementAssociationGroup';
import PropertyResidentSettingsGroup from 'utils/SmartForm/groups/PropertyResidentSettingsGroup';
import PropertyGuestSettingsGroup from 'utils/SmartForm/groups/PropertyGuestSettingsGroup';
import PropertyImportFileGroup from 'utils/SmartForm/groups/PropertyBulkImportGroup';
import PropertyUnitsGroup from 'utils/SmartForm/groups/PropertyUnitsGroup';

import styles from 'view/private/Body/Property/PropertyCreate/index.module.css';

export class PropertyCreate extends Component {

  state = {
    stepIndex: 0,
    propertyObj: {},
    showUnsavedPrompt: false,
  };

  render() {
    return (
      <div className={styles.container}>
        <Stepper
          activeStep={this.state.stepIndex}
          orientation='horizontal'
          sx={{
            '& .MuiStepLabel-root .Mui-completed': { color: '#0EC47E' },
            '& .MuiStepLabel-label.Mui-completed': { color: '#000000' },
            '& .MuiStepLabel-root .Mui-active': { color: '#0EC47E' },
            '& .MuiStepLabel-label.Mui-active': { color: '#000000' },
          }}
        >
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 0 })}>Property Info</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 1 })}>Enforcement</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 2 })}>Guest Settings</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 3 })}>Resident Settings</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 4 })}>Bulk Unit Import</StepButton>
          </Step>
          <Step>
            <StepButton onClick={() => this.setState({ stepIndex: 5 })}>Units</StepButton>
          </Step>
        </Stepper>

        <Card className={styles.cardColor}>
          {this.renderSteps()}
        </Card>
        <Prompt
          when={this.displayUnsavedChangesPrompt()}
          message='You have unsaved changes. All changes will be lost. Are you sure you want to leave?'
        />
      </div>
    );
  }
  
  renderSteps() {
    const { stepIndex, propertyObj } = this.state;
    switch (stepIndex) {
      case 0: 
        return (
          <PropertyAddressGroup 
            nextStep={() => this.setState({ stepIndex: 1 })} 
            propertyObj={propertyObj}
            buildProperty={propertyObj => this.setState({ propertyObj })}
          />);
      case 1: 
        return (
          <PropertyEnfrocementAssociationGroup
            prevStep={() => this.setState({ stepIndex: 0 })}  
            nextStep={() => this.setState({ stepIndex: 2 })}
            propertyObj={propertyObj}
            buildProperty={propertyObj => this.setState({ propertyObj })}
          />);
      case 2: 
        return (
          <PropertyGuestSettingsGroup
            prevStep={() => this.setState({ stepIndex: 1 })}
            nextStep={() => this.setState({ stepIndex: 3 })}
            propertyObj={propertyObj}
            buildProperty={propertyObj => this.setState({ propertyObj })}
          />);
      case 3: 
        return (
          <PropertyResidentSettingsGroup
            prevStep={() => this.setState({ stepIndex: 2 })}
            nextStep={() => this.setState({ stepIndex: 4 })}
            propertyObj={propertyObj}
            buildProperty={propertyObj => this.setState({ propertyObj })}
          />);
      case 4: 
        return (
          <PropertyImportFileGroup
            prevStep={() => this.setState({ stepIndex: 3 })}
            nextStep={() => this.setState({ stepIndex: 5 })}
            propertyObj={propertyObj}
            buildProperty={propertyObj => this.setState({ propertyObj })}
          />);
      case 5:
        return (
          <PropertyUnitsGroup
            prevStep={() => this.setState({ stepIndex: 4 })}
            propertyObj={propertyObj}
            buildProperty={propertyObj => this.setState({ propertyObj })}
          />);     
      default:
        break;
    }
  }
  
  displayUnsavedChangesPrompt() {
    return !isEmpty(this.state.propertyObj);
  }

}

export default withRouter(PropertyCreate);