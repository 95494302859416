import React, { Component } from 'react';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import PropertyManageEmployeeForm from 'view/private/Body/Property/PropertyManageEmployee/PropertyManageEmployeeForm/index';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

import styles from 'view/private/Body/Property/PropertyManageEmployee/index.module.css';

export class PropertyManageEmployee extends Component {

  state = {
    isLoading: false,
  };
  
  render() {
    const { isLoading } = this.state;
    const { id } = this.props.match.params;
    const pageLabel = id ? 'edit employee' : 'add employee';
    
    return (
      <LoadingOverlay
        show={isLoading}
        width='100%'
      >
        <div className={styles.container}>
          <div className={styles.title}>{pageLabel}</div>
          <SmartFormProvider> 
            <PropertyManageEmployeeForm /> 
          </SmartFormProvider> 
        </div>
      </LoadingOverlay>
    );
  }
}

  
export default PropertyManageEmployee;
