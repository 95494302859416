import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';
import Add from '@mui/icons-material/ArrowForward';
import Remove from '@mui/icons-material/ArrowBack';

import TextInput from 'utils/SmartForm/components/TextInput';

import { 
  getPermissions,
  getRolesPermissions,
  createRole,
  updateRole,
} from 'view/private/Body/Staff/actions';

import { addAlert } from 'utils/components/alerts/actions';

import { toggleLoaderOn, toggleLoaderOff } from 'view/actions';

import styles from 'view/private/Body/Staff/RolesManage/RolesManageForm/index.module.css';

const select = (state) => ({
  permissions: state.staff.permissions,
});

export class RolesManageForm extends Component {

  static propTypes = {
    permissions: PropTypes.array.isRequired,
    getPermissions: PropTypes.func.isRequired,
    getRolesPermissions: PropTypes.func.isRequired,
    createRole: PropTypes.func.isRequired,
    updateRole: PropTypes.func.isRequired,
    toggleLoaderOn: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
  };

  state = {
    unusedPermissions:[],
    usedPermissions: [],
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const roleId = this.props.match.params.id;
    this.props.toggleLoaderOn();
    if (roleId) {
      this.props.getRolesPermissions(roleId)
        .then(response => {
          const data = response.payload.data;
          
          // hydrate role name
          const inputList = cloneDeep(this.context.inputList);
          inputList.forEach(input => {
            if (input.inputName === 'roleName') {
              input.inputValue = data.role_name;
            }
          });
          this.context.updateInputList(inputList);
        
          // hydrate permissions
          const usedPermissions = data.permissions.filter(permission => permission.access === true);
          const unusedPermissions = data.permissions.filter(permission => permission.access === false);
          this.setState({ usedPermissions, unusedPermissions });
        })
        .catch(error => {
          console.log(JSON.stringify(error)); // eslint-disable-line
        })
        .finally(() => {
          this.props.toggleLoaderOff();
        });
    }
    else {
      this.props.getPermissions()
        .then(() => {
          this.setState({ unusedPermissions: this.props.permissions });
        })
        .catch(error => {
          console.log(JSON.stringify(error)); // eslint-disable-line
        })
        .finally(() => {
          this.props.toggleLoaderOff();
        });
    }
  }

  render() {
    const roleId = this.props.match.params.id;
    const btnLabel = roleId ? 'edit role' : 'add role';
    return (
      <React.Fragment>
        <div style={{ marginTop: '15px', height: '80px' }}>
          <TextInput
            inputName='roleName'
            isRequired={true}
            labelText='Role Name'
            style={{ width: '240px' }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'grid', marginRight: '20px', marginTop: '5px', backgroundColor: '#EEE', borderRadius: '10px', padding: '10px', alignContent: 'start', minHeight: '500px' }}>
            <div className={styles.unused}>Unassigned Permissions</div>
            {this.renderUnused()}
          </div>
          <div style={{ display: 'grid', marginTop: '5px', backgroundColor: '#EEE', borderRadius: '5px', padding: '10px', alignContent: 'start', }}>
            <div className={styles.used}>Assigned Permissions</div>
            {this.renderUsed()}
          </div>
        </div>
        <div style={{ marginTop: '15px' }}>
          <Button
            onClick={() => this.props.history.push('/staff/roleslist')}
            style={{ width: '175px' }}
          >
            cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            onClick={() => this.manageRole()}
            style={{ width: '175px' }}
          >
            {btnLabel}
          </Button>
        </div>
      </React.Fragment>
    );
  }

  manageRole() {
    const roleId = this.props.match.params.id;
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);
    if (validationObject.isValid) {
      const result = this.context.prepInputList(validationObject.inputList);
      const unusedPermissions = this.state.unusedPermissions.map(permission => {
        return ([ permission.permission_id, 0 ]);
      });
      const usedPermissions = this.state.usedPermissions.map(permission => {
        return ([ permission.permission_id, 1 ]);
      });
      result.permissions = [ ...unusedPermissions, ...usedPermissions ];
      
      if (roleId) { // update role
        result.roleId = Number(roleId);
        this.props.toggleLoaderOn();
        this.props.updateRole(result)
          .then(() => {
            this.props.addAlert('success', 'Role successfully updated.');
            this.props.history.push('/staff/roleslist');
          })
          .catch(error => {
            console.log(JSON.stringify(error)) // eslint-disable-line
          })
          .finally(() => {
            this.props.toggleLoaderOff();
          });
      }
      else {
        this.props.toggleLoaderOn();
        this.props.createRole(result)
          .then(() => {
            this.props.addAlert('success', 'Role successfully created.');
            this.props.history.push('/staff/roleslist');
          })
          .catch(error => {
            console.log(JSON.stringify(error)) // eslint-disable-line
          })
          .finally(() => {
            this.props.toggleLoaderOff();
          });
      }
    }
    else {
      this.context.updateInputList(validationObject.inputList);
    }
  }

  renderUsed() {
    const { usedPermissions } = this.state;
    if (usedPermissions.length === 0) {
      return (
        <div id='claims-unused' style={{ textAlign: 'center', marginTop: '10px' }}>
          No Permisions added yet.<br />You must add at least one permission.
        </div>
      );
    }
    
    return usedPermissions.map((permission, index) => {
      return (
        <div onClick={() => this.handleRemove(index)} className={styles.addStaff} key={index}>
          <Remove />
          <span style={{ width: '100%', textAlign: 'right' }}>
            {permission.permission_name}
          </span>
        </div>
      );
    });
  }

  renderUnused() {
    const { unusedPermissions } = this.state;
    if (unusedPermissions.length === 0) {
      return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          All permissions are used.
        </div>
      );
    }    
    
    return unusedPermissions.map((permission, index) => {
      return (
        <div onClick={() => this.handleAdd(index)} className={styles.addStaff} key={index}>
          <span style={{ width: '100%' }}>
            {permission.permission_name}
          </span>
          <Add />
        </div>
      );
    });
  }

  handleAdd(id) {
    const { usedPermissions, unusedPermissions } = cloneDeep(this.state);
    usedPermissions.push(unusedPermissions[id]);
    unusedPermissions.splice(id, 1);
    this.setState({ unusedPermissions, usedPermissions });
  }

  handleRemove(id) {
    const { usedPermissions, unusedPermissions } = cloneDeep(this.state);
    unusedPermissions.push(usedPermissions[id]);
    usedPermissions.splice(id, 1);
    this.setState({ unusedPermissions, usedPermissions });
  }

}

export default withRouter(connect(select, {
  getPermissions,
  getRolesPermissions,
  createRole,
  updateRole,
  toggleLoaderOn,
  toggleLoaderOff,
  addAlert,
})(RolesManageForm));