export const hourRange = [
  { id: 0, name: '00:00' },
  { id: 1, name: '00:30' },
  { id: 2, name: '01:00' },
  { id: 3, name: '01:30' },
  { id: 4, name: '02:00' },
  { id: 5, name: '02:30' },
  { id: 6, name: '03:00' },
  { id: 7, name: '03:30' },
  { id: 8, name: '04:00' },
  { id: 9, name: '04:30' },
  { id: 10, name: '05:00' },
  { id: 11, name: '05:30' },
  { id: 12, name: '06:00' },
  { id: 13, name: '06:30' },
  { id: 14, name: '07:00' },
  { id: 15, name: '07:30' },
  { id: 17, name: '08:00' },
  { id: 18, name: '08:30' },
  { id: 19, name: '09:00' },
  { id: 20, name: '09:30' },
  { id: 21, name: '10:00' },
  { id: 22, name: '10:30' },
  { id: 23, name: '11:00' },
  { id: 24, name: '11:30' },
  { id: 25, name: '12:00' },
  { id: 26, name: '12:30' },
  { id: 27, name: '13:00' },
  { id: 28, name: '13:30' },
  { id: 29, name: '14:00' },
  { id: 30, name: '14:30' },
  { id: 31, name: '15:00' },
  { id: 32, name: '15:30' },
  { id: 33, name: '16:00' },
  { id: 34, name: '16:30' },
  { id: 35, name: '17:00' },
  { id: 36, name: '17:30' },
  { id: 37, name: '18:00' },
  { id: 38, name: '18:30' },
  { id: 39, name: '19:00' },
  { id: 40, name: '19:30' },
  { id: 41, name: '20:00' },
  { id: 42, name: '20:30' },
  { id: 43, name: '21:00' },
  { id: 44, name: '21:30' },
  { id: 45, name: '22:00' },
  { id: 46, name: '22:30' },
  { id: 47, name: '23:00' },
  { id: 48, name: '23:30' },
];

export const permitTypes = [
  { id: 0, name: 'Assigned Covered' },
  { id: 1, name: 'Assigned Uncovered' },
  { id: 2, name: 'Detached Garage' },
  { id: 3, name: 'Open Lot' },
  { id: 4, name: 'Covered' },
  { id: 5, name: 'Guest' },
];

export const guestPermitTypes = [
  { id: 0, name: 'Guest' },
  { id: 1, name: 'Guest - Assigned' },
  { id: 2, name: 'Guest - Assigned Covered' },
  { id: 3, name: 'Guest - Assigned Uncovered' },
  { id: 4, name: 'Guest - Detached Garage' },
  { id: 5, name: 'Guest - Open Lot' },
];

export const permitDurationType = [
  { id: 0, name: 'Days' },
  { id: 1, name: 'Months' },
  { id: 2, name: 'Years' },
];

export const assignmentMethod = [
  { id: 0, name: 'First Come, First Serve' },
  { id: 1, name: 'By Unit' },
  { id: 2, name: 'By User' },
];

export const guestAssignmentMethod = [
  { id: 0, name: 'First Come, First Serve' },
  { id: 1, name: 'By User' },
];

export const numberRooms = [
  { id: 1, name: '1 Rooms' },
  { id: 2, name: '2 Rooms' },
  { id: 3, name: '3 Rooms' },
  { id: 4, name: '4 Rooms' },
];

export const permitLimits = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
];

export const maxPermits = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: 'No Limit' },
];

export const activeGuestPermitLimit = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: 'No Limit' },
];

export const quantityLimit = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: 'No Limit' },
];

export const consecutiveDays = [
  { id: 'none', name: 'None' },
  { id: '1', name: '1 Day' },
  { id: '2', name: '2 Days' },
  { id: '3', name: '3 Days' },
  { id: '4', name: '4 Days' },
  { id: '5', name: '5 Days' },
  { id: '6', name: '6 Days' },
  { id: '7', name: '7 Days' }
];

export const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

export function activeGuestLimit() {
  const list = [];
  list.push({ value: 0, display: 'No Limit' });
  for (let i = 1; i < 201; i++) {
    list.push({ value: i, display: i });
  }
  return list;
}

export const PREV = 'prev';
export const NEXT = 'next';