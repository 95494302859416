import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import { cloneDeep } from 'lodash';

import { Button } from '@mui/material';

import PublishIcon from '@mui/icons-material/Publish';

import { Modal } from '@timber-rider/parkingtree-client-common';

import { parseCSV } from 'utils/SmartForm/groups/PropertyBulkImportGroup/helper';

import styles from 'utils/SmartForm/groups/PropertyBulkImportGroup/index.module.css';

export class PropertyImportFileGroup extends Component {
  
  static propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    buildProperty: PropTypes.func.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };
  
  state = {
    hasImported: false,
    isCSVFiletype: false,
  };
  
  render() {
    const { hasImported } = this.state;
    return (
      <>
        <div className={styles.pageTitle}>Bulk Unit Import</div>
        <div style={{ marginLeft: '15%', width: '50%', paddingBottom: '20px' }}>
          <strong>NOTE: </strong>Here are the file requirements:
          <ul>
            <li>The file must be a comma seperated values file with a .csv file extension.</li>
            <li>The first row of the file should be column headers, the first of the two headers must be unit.</li>
            <li>The file is required to provide a unit number or address on every row of this file. </li>
            <li>The lease/permit expiration date is required.</li>
          </ul>
        </div>
        <div className='container'>
          {!hasImported &&
          <DropZone onDrop={incomingFile => this.parseFile(incomingFile)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ cursor: 'grab' }}>
                <input {...getInputProps()} />
                <div className={styles.dropZone} {...getRootProps()}>
                  <div style={{ height: '78px' }}>
                    <div style={{ fontSize: '18px', fontWeight: 'bold' }}>Drop a CSV file here or click to import</div>
                    <div style={{ fontSize: '15px' }}>Maximum upload file size 2MB</div>
                    <PublishIcon style={{ fontSize: '45px' }} />
                  </div>
                </div>
              </section>
            )}
          </DropZone>}
        </div>
        <div style={{ paddingTop: '20px', paddingBottom: '10px' }}>
          <Button
            variant='contained'
            onClick={() => this.props.prevStep()}
          >
            previous
          </Button>&nbsp;
          <Button
            variant='contained'
            onClick={() => this.props.nextStep()}
          >
            skip
          </Button>
        </div>
      </>
    );
  }

  parseFile(files) {
    const file = files[0];
    if (file.type.includes('csv')) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const rows = parseCSV(reader.result);
        const propertyObj = cloneDeep(this.props.propertyObj);
        const { Resident } = propertyObj;
        rows.forEach(row => {
          row.permits = Resident.permits;
        });
        propertyObj.Units = rows;
        this.props.buildProperty(propertyObj);
        this.props.nextStep();
      };
    }
  }

  confirmCSVFileType() {
    const actionButtons = [
      {
        label: 'OK',
        action: () => this.setState({ isCSVFiletype: false }),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ isCSVFiletype: false })}
        show={this.state.isCSVFiletype}
        title='file type'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          The file must be a comma seperated values (CSV) text file.
        </div>
      </Modal>
    );
  }
}

export default PropertyImportFileGroup;