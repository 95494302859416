export const GET_ROLES = 'Staff/GET_ROLES';
export const GET_ROLES_PERMISSIONS = 'Staff/GET_ROLES_PERMISSIONS';

export const CREATE_ROLE = 'Staff/CREATE_ROLE';
export const UPDATE_ROLE = 'Staff/UPDATE_ROLE';

export const CREATE_USER = 'Staff/CREATE_USER';
export const UPDATE_USER = 'Staff/UPDATE_USER';
export const GET_USER = 'Staff/GET_USER';

export const GET_USERS_BY_ORG = 'Staff/GET_USERS_BY_ORG';

export const GET_PERMISSIONS = 'Staff/GET_PERMISSIONS';