import React, { Component } from 'react';

import { Button } from '@mui/material';

import styles from 'view/public/PageNotFound/index.module.css';

export class PageNotFound extends Component {
  
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={ require('utils/media/logo.png') } alt='The Parking Tree' height='70px' />
        </div>
        <div className={styles.shrug}>{'¯\\_(ツ)_/¯'}</div>
        <p className={styles.message}>We cant seem to find the page youre looking for.</p>
        <Button
          variant='contained'
          onClick={() => this.props.history.push('/')}
        >
          Start Over!
        </Button>
      </div>
    );
  }
}

export default PageNotFound;