import React, { Component } from 'react';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import EnforcementManageForm from 'view/private/Body/Enforcement/EnforcementManage/EnforcementManageForm/index';

import styles from 'view/private/Body/Enforcement/EnforcementManage/index.module.css';

export class EnforcementManage extends Component {
  render() {
    const { EnforcementId } = this.props.match.params;
    const pageLabel = EnforcementId ? 'MANAGE' : 'ADD';
    
    return (
      <div className={styles.pageContainer}>
        <div className={styles.pageTitle}>{pageLabel} Enforcement Company</div>
        <div className={styles.container}>  
          <SmartFormProvider>
            <EnforcementManageForm />
          </SmartFormProvider>
        </div>
      </div>
    );
  }
}

  
export default EnforcementManage;