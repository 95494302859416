import React, { Component } from 'react';

// import './styles.css';

export class DashBoard extends Component {
  render() {
    return (
      <div className='DashBoard_container'>
        Dashboard Stuff Will Go Right Here
      </div>
    );
  }
}

export default DashBoard;