import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  Button,
} from '@mui/material';

import { Modal } from '@timber-rider/parkingtree-client-common';

import { 
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@timber-rider/parkingtree-client-common';

import styles from 'view/private/Body/Property/PropertyEmployee/index.module.css';

const select = (state) => ({
  propertyEmployees: state.propertys.propertyEmployees,
});

export class PropertyEmployee extends Component {

  static propTypes = {
    propertyEmployees: PropTypes.array.isRequired,
  };

  state = {
    isLoading: false,
    confirmDeleteEmployee: false,
    confirmDeleteEmployeeId: null,
  };

  render() {
    const { isLoading } = this.state;
    return (
      <div className={styles.container}>
        <div style={{ marginTop: '10px' }}>
          <div className={styles.addEmployee}>
            <Button
              variant='contained'
              onClick={() => this.props.history.push('/property-management/employee/manageemployee/addemployee')}
            >
              add employee
            </Button>
          </div>            
          
          <SmartTable
            idKey='id'
            rows={this.props.propertyEmployees}
            title='Employees'
            loading={isLoading}
            columns={[
              {
                key: 'fullName',
                title: 'Name',
                type: 'string',
              },
              {
                key: 'roleType',
                title: 'Role',
                type: 'string',
              },
            ]}
            actions={[
              {
                displayName: 'Edit Employee',
                type: 'menu',
                onSelect: row => this.props.history.push(`/property-management/employee/manageemployee/${row.id}`),
              },
              {
                displayName: 'Delete Employee',
                type: 'menu',
                onSelect: row => this.setState({ confirmDeleteEmployee: true, confirmDeleteEmployeeId: (row.id) }),
                showIf: () => true,
              },
            ]}
          >
            <TableToolbar />
            <TableContainer maxHeight='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable> 
        </div>  
        {this.confirmDeleteEmployee(this.state.confirmDeleteEmployeeId)}
      </div>
    );
  }

  confirmDeleteEmployee(id) {
    const actionButtons = [
      {
        label: 'OK',
        action: () => {
          this.setState(
            { confirmDeleteEmployee: false },
            () => this.deleteEmployee(id)
          );
        },
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDeleteEmployee: false })}
        show={this.state.confirmDeleteEmployee}
        title='Confirm Delete'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          Are you sure you want to delete this employee?
        </div>
      </Modal>
    );
  }

  deleteEmployee(id) {
    alert(`The id = ${id}`);
  }

}

export default withRouter(connect(select, null)(PropertyEmployee));