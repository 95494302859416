import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import {
  clearStore,
  createPassword,
  toggleLoaderOn,
  toggleLoaderOff,
} from 'view/actions';

import PasswordInput from 'utils/SmartForm/components/PasswordInput';

import { PasswordRequirements } from '@timber-rider/parkingtree-client-common';
import { PASSWORD_REQUIREMENTS } from 'view/constants';

import { Button } from '@mui/material';

import { addAlert } from 'utils/components/alerts/actions';

import styles from 'view/public/CreatePassword/CreatePasswordForm/index.module.css';

const select = state => ({
  userDetails: state.session.userDetails,
  otp: state.session.otp,
});

export class CreatePasswordForm extends Component {
  
  static propTypes = {
    createPassword: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    otp: PropTypes.string,
    addAlert: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    toggleLoaderOn: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
  };

  state = {
    isPasswordValid: false,
    pass: undefined,
  };

  static contextType = SmartFormContext;

  componentDidUpdate(prevProps, prevState) {
    const inputList = this.context.inputList;
    const pass = inputList.find(input => input.inputName === 'pass');
    if (pass && pass.inputValue !== prevState.pass) {
      if (pass) this.setState({ pass: pass.inputValue });
    }
  }
  
  render() {
    const { isPasswordValid, pass } = this.state;

    return (
      <>
        <div className={styles.container}>
          <div className={styles.password}>
            <PasswordInput
              inputName='pass'
              isRequired={true}
              labelText='Password'
              style={{ width: '300px' }}
              inputFocus={true}
            />
          </div>
          <div className={styles.passwordRequirements}>
            <div style={{ margin: 'auto', width: '300px' }}>
              <PasswordRequirements
                password={pass || ''}
                passwordRequirements={PASSWORD_REQUIREMENTS}
                onPasswordCheck={isValid => this.setState({ isPasswordValid: isValid })}
              />
            </div>
          </div>
          <div className={styles.btns}>
            <Button
              variant='contained'
              style={{ width: '200px' }}
              disabled={!isPasswordValid}
              onClick={() => this.createPassword()}
            >
              create
            </Button>
          </div>
        </div>
      </>
    );
  }

  createPassword() {
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      const formattedObject = this.context.prepInputList(validObj.inputList);
      formattedObject.email = this.props.userDetails.email;
      this.props.toggleLoaderOn();
      this.props.createPassword(formattedObject)
        .then(() => {
          this.props.addAlert('success', 'You have successfully updated your password.');
          this.props.history.push('/');
        })
        .catch(() => { // otp token not found, bad request returned
          this.props.clearStore();
          this.props.history.push('/');
        })
        .finally(() => {
          this.props.toggleLoaderOff();
        });
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }
}

export default withRouter(connect(select, {
  createPassword,
  addAlert,
  clearStore,
  toggleLoaderOn,
  toggleLoaderOff,
})(CreatePasswordForm));