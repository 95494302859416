import React, { Component } from 'react';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';
import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import EnforcementEmployeeForm from 'view/private/Body/Enforcement/EnforcementEmployeeManage/EnforcementEmployeeManageForm/index';

import styles from 'view/private/Body/Enforcement/EnforcementEmployeeManage/index.module.css';

export class EnforcementEmployee extends Component {

  state = {
    isLoading: false,
  };

  render() {
    const { isLoading } = this.state;
    const { employeeId } = this.props.match.params;
    const pageLabel = employeeId ? 'edit employee' : 'add employee';
  
    return (
      <LoadingOverlay
        show={isLoading}
        width='100%'
      >
        <div className={styles.container}>
          <div className={styles.title}>{pageLabel}</div>
          <SmartFormProvider>
            <EnforcementEmployeeForm />
          </SmartFormProvider>
        </div>
      </LoadingOverlay>
    );
  }
}


export default EnforcementEmployee;