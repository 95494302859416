import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from '@mui/material';

import { IconBtnTooltip } from '@timber-rider/parkingtree-client-common';
import { HtmlEditor } from '@timber-rider/parkingtree-client-common';

import styles from 'view/private/Body/TaskQueue/CustomerSupportModal/index.module.css';

export function Modal({
  DialogContentProps,
  DialogProps,
  show,
  onClose,
}) {
  const title = 'Customer Support';
  return (
    <Dialog
      open={show}
      onClose={onClose}
      maxWidth={'xl'}
      {...DialogProps}
    >
      <div className={styles.titleContainer}>
        <DialogTitle 
          disabletypography='true' /*disableTypography was there before but it was causing an error*/
          style={{ margin: 0, padding: '10px 0', fontWeight: 'bold', fontSize: 'x-large' }}
        >
          {title.toUpperCase()}
        </DialogTitle>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
      </div>

      <Divider style={{ margin: '0 20px' }} />
      
      <DialogContent {...DialogContentProps}>
        <HtmlEditor sendMessage={msg => alert(msg)} />
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  DialogProps: PropTypes.object,
  DialogContentProps: PropTypes.object,
  //isLoading: false,
};


export default Modal;