import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@mui/material';

import { 
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@timber-rider/parkingtree-client-common';

import { Modal } from '@timber-rider/parkingtree-client-common';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import styles from 'view/private/Body/Utilities/PermitTypes/PermitTypeList/index.module.css';

const select = (state) => ({
  permitTypes: state.propertys.permitTypes,
});

export class PropertyPermitType extends Component {

  static propTypes = {
    permitTypes: PropTypes.array.isRequired,
    updateUtility: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    confirmDelete: false,
    propertyDeleteId: -1
  };

  static contextType = SmartFormContext;

  render() {
    const { loading } = this.state;
    const { permitTypes } = this.props;
    return (
      <div className={styles.container}>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant='contained'
            onClick={() => this.props.updateUtility(1)}
          >
            add permit type
          </Button>
        </div>
        <SmartTable
          idKey='id'
          title='Permit Types'
          rows={permitTypes}
          loading={loading}
          columns={[
            {
              key: 'permit',
              title: 'Permit',
              type: 'string',
            },
          ]}
          actions={[
            {
              displayName: 'Edit Permit',
              type: 'menu',
              onSelect: () => this.props.updateUtility(1),
            },
            {
              displayName: 'Delete Permit',
              type: 'menu',
              onSelect: row => this.setState({ confirmDelete: true, propertyDeleteId: row.id }),
              showIf: () => true,
            },
          ]}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
        {this.confirmDelete()}
      </div>
    );
  }

  confirmDelete() {
    const actionButtons = [
      {
        label: 'OK',
        action: () => this.deletePermitType(),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDelete: false })}
        show={this.state.confirmDelete}
        title='Confirm Delete'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          Are you sure you want to delete this permit?
        </div>
      </Modal>
    );
  }

  deletePermitType() {
    this.setState({ confirmDelete: false });
  }

}

export default connect(select, null)(PropertyPermitType);