import { combineReducers } from '@reduxjs/toolkit';

import { INITIALIZE_STORE } from './view/constants';

import Session from 'view/reducer';
import EnforcementReducer from 'view/private/Body/Enforcement/reducer';
import Alerts from 'utils/components/alerts/reducer';
import PropertysReducer from 'view/private/Body/Property/reducer';
import StaffReducer from 'view/private/Body/Staff/reducer';

// Ability to reinitialize the entire store. This action type is used by logout
const rootReducer = (state, action) => {
  if (action.type === INITIALIZE_STORE) {
    state = {};
  }  
  return appReducer(state, action);
};

const appReducer = combineReducers({
  session: Session,
  enforcement: EnforcementReducer,
  alerts: Alerts,
  propertys: PropertysReducer,
  staff: StaffReducer,
});

export default rootReducer;