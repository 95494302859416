export const propertyList = [
  { id: 0, name: 'Mountain View Apartments', Address1: '123 Hartford Ln', Address2: '', City: 'Conroe', State: 'TX', ZIP: '77341', Phone: '(614) 775-2534', email: 'aaa@a.com' },
  { id: 1, name: 'Peak Townhomes', Address1: '812 N Robson', Address2: 'Suite A', City: 'Mesa', State: 'AZ', ZIP: '85201', Phone: '(480) 432-8599', email: 'triple@a.com' },
  { id: 2, name: 'Mountain Crest Condominiums', Address1: '1432 Emerald Ln', Address2: 'Apt 323', City: 'Mesa', State: 'AZ', ZIP: '85234', Phone: '(602) 323-5478', email: 'tow@lift.com' },
  { id: 3, name: 'Spring Creek Apartments', Address1: '1570 Broadway', Address2: '', City: 'Chandler', State: 'AZ', ZIP: '85204', Phone: '(602) 561-5768', email: 'tow@autocare.com' },
];

export const permitTypes = [
  { id: 0, permit: 'Assigned Covered' },
  { id: 1, permit: 'Assigned Uncovered' },
  { id: 2, permit: 'Detached Garage' },
  { id: 3, permit: 'Open Lot' },
  { id: 4, permit: 'Covered' },
  { id: 5, permit: 'Guest' },
];

export const propertyEmployees = [
  { id: 0, firstName: 'Rick', lastName: 'Sanchez', fullName: 'Rick Sanchez', roleType: 'Administrator' },
  { id: 1, firstName: 'Morty', lastName: 'Smith', fullName: 'Morty Smith', roleType: 'Office' },
  { id: 2, firstName: 'Beth', lastName: 'Smith', fullName: 'Beth Smith', roleType: 'Office' },
  { id: 3, firstName: 'Summer', lastName: 'Smith', fullName: 'Summer Smith', roleType: 'Office' },
  { id: 4, firstName: 'Jerry', lastName: 'Smith', fullName: 'Jerry Smith', roleType: 'Office' },
];

export const enforcementList = [
  { id: 0, EnforcementName: 'AAA Towing', Address1: '123 Hartford Ln', Address2: '', City: 'Conroe', State: 'TX', ZIP: '77341', Phone: '(614) 775-2534', email: 'aaa@a.com' },
  { id: 1, EnforcementName: 'Triple AAA Towing', Address1: '812 N Robson', Address2: 'Suite A', City: 'Mesa', State: 'AZ', ZIP: '85201', Phone: '(480) 432-8599', email: 'triple@a.com' },
  { id: 2, EnforcementName: 'Lift Towing', Address1: '1432 Emerald Ln', Address2: 'Apt 323', City: 'Mesa', State: 'AZ', ZIP: '85234', Phone: '(602) 323-5478', email: 'tow@lift.com' },
  { id: 3, EnforcementName: 'Autocare Towing', Address1: '1570 Broadway', Address2: '', City: 'Chandler', State: 'AZ', ZIP: '85204', Phone: '(602) 561-5768', email: 'tow@autocare.com' },
];

export const permissions = {
  EditRole: true,
  EditStaff: true,
  EditProperty: true,
  EditEnforcement: true,
};