import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from '@mui/material';

import { Modal } from '@timber-rider/parkingtree-client-common';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import TextInput from 'utils/SmartForm/components/TextInput';

const select = (state) => ({
  permitTypes: state.propertys.permitTypes,
});

export class PermitTypeManageForm extends Component {
  
  static propTypes = {
    permitTypes: PropTypes.array.isRequired,
  };

  state = {
    samePermitType: false,
  };

  static contextType = SmartFormContext;

  render() {
    return (
      <div style={{ padding: '10px' }}>
        <div style={{ height: '75px', marginTop: '5px', marginBottom: '10px' }}>
          <TextInput
            inputName='permitType'
            isRequired={true}
            labelText='Permit Type'
            style={{ width: '240px' }}
            autoComplete={'off'}
          />
        </div>
        <div>
          <Button
            onClick={() => this.props.history.push('/utilities')}
            style={{ width: '55px' }}
          >
            cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            onClick={() => this.managePermitType()}
            style={{ width: '175px', marginLeft: '5px' }}
          >
            add permit type
          </Button>
        </div>
        {this.samePermitType()}
      </div>
    );
  }

  managePermitType() {
    const validObj = this.context.checkRequiredInputs(this.context.inputList);
    if (validObj.isValid) {
      this.props.permitTypes.forEach(permitType => {
        validObj.inputList.forEach(input => {
          if (permitType.permit.toLowerCase() === input?.inputValue.toLowerCase()) {
            this.setState({ samePermitType: true });
          }
        });
      });
      // Add New Permit Type to API
      const inputs = this.context.prepInputList(validObj.inputList);
      alert(JSON.stringify(inputs));
    }
    else {
      this.context.updateInputList(validObj.inputList);
    }
  }

  samePermitType() {
    const actionButtons = [
      {
        label: 'OK',
        action: () => this.deletePermitType(),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ samePermitType: false })}
        show={this.state.samePermitType}
        title='Permit Type Already Exists'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          Please enter a Permit Type name that does not already exists.
        </div>
      </Modal>
    );
  }

  deletePermitType() {
    this.setState({ samePermitType: false });
  }

}
export default withRouter(connect(select, null)(PermitTypeManageForm));