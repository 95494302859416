import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import PropertyUnitsGroupForm from 'utils/SmartForm/groups/PropertyUnitsGroup/PropertyUnitsGroupForm/index';


export class PropertyUnitsGroup extends Component {

  static propTypes = {
    prevStep: PropTypes.func.isRequired,
    buildProperty: PropTypes.func.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };

  state = {
    propertyObj: this.props.propertyObj,
  };

  render() {
    const { propertyObj } = this.state;
    return (
      <SmartFormProvider> 
        <PropertyUnitsGroupForm 
          prevStep={this.props.prevStep} 
          propertyObj={propertyObj}
          buildProperty={this.props.buildProperty}
        /> 
      </SmartFormProvider>
    );
  }
}

  
export default withRouter(connect(null, null)(PropertyUnitsGroup));
