import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@mui/material';

import { 
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
  Modal
} from '@timber-rider/parkingtree-client-common';

import { toggleLoaderOn, toggleLoaderOff, resetPassword } from 'view/actions';
import { getUsersByOrganization } from 'view/private/Body/Staff/actions';
import { addAlert } from 'utils/components/alerts/actions';

import styles from 'view/private/Body/Staff/StaffList/index.module.css';

const select = (state) => ({
  staffList: state.staff.staffList,
});

export class StaffList extends Component {

  static propTypes = {
    staffList: PropTypes.array.isRequired,
    getUsersByOrganization: PropTypes.func.isRequired,
    toggleLoaderOn: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    addAlert: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    confirmDelete: false,
    confirmPasswordReset: false,
    user_id: undefined,
  };

  componentDidMount() {
    const parkingTreeOrgId = 0; // this will always be true
    this.props.toggleLoaderOn();
    this.props.getUsersByOrganization(parkingTreeOrgId)
      .then(() => {
        //
      })
      .catch(error => {
        console.log(JSON.stringify(error)); // eslint-disable-line
      })
      .finally(() => {
        this.props.toggleLoaderOff();
      });
  }

  render() {
    const { loading } = this.state;
    const { staffList } = this.props;
    return (
      <div className={styles.container}>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant='contained'
            onClick={() => this.props.history.push('/staff/roleslist')}
            style={{ marginRight: '10px' }}
          >
            manage roles
          </Button>
          <Button
            variant='contained'
            onClick={() => this.props.history.push('/staff/staffmanage')}
          >
            add staff
          </Button>
        </div>
        <SmartTable
          idKey='user_id'
          title='ParkingTree Staff'
          rows={staffList}
          loading={loading}
          columns={[
            {
              key: 'fullName',
              title: 'Name',
              type: 'string',
            },
            {
              key: 'role_name',
              title: 'Role',
              type: 'string',
            },
          ]}
          actions={[
            {
              displayName: 'Edit Person',
              type: 'menu',
              onSelect: row => this.props.history.push(`/staff/staffmanage/${row.user_id}`),
            },
            {
              displayName: 'Delete Person',
              type: 'menu',
              onSelect: row => this.setState({ confirmDelete: true, user_id: row.user_id }),
              showIf: () => true,
            },
            {
              displayName: 'Send Password Reset',
              type: 'menu',
              onSelect: row => this.setState({ confirmPasswordReset: true, user_id: row.user_id }),
              showIf: () => true,
            },
          ]}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
        {this.confirmDelete()}
        {this.passwordReset()}
      </div>
    );
  }

  confirmDelete() {
    const actionButtons = [
      {
        label: 'yes',
        action: () => alert('works needs to be done'),
        buttonType: 'text',
      },
      {
        label: 'no',
        action: () => this.setState({ confirmDelete: false }),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDelete: false })}
        show={this.state.confirmDelete}
        title='Confirm Delete'
      >
        <div style={{ margin: '0 0%', height: '55px' }}>
          Are you sure you want to delete this staff? This action is irreversible.
        </div>
      </Modal>
    );
  }

  passwordReset() {
    const actionButtons = [
      {
        label: 'yes',
        action: () => this.resetPassword(this.state.user_id),
        buttonType: 'text',
      },
      {
        label: 'no',
        action: () => this.setState({ confirmPasswordReset: false }),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ confirmPasswordReset: false })}
        show={this.state.confirmPasswordReset}
        title='Confirm Password Reset'
      >
        <div style={{ margin: '0 0%', height: '55px' }}>
          Are you sure you want to reset this users password?<br /><br />

          This will delete this persons password and send an email with a One Time Password and instructions to reset.
          Make sure we have the correct email before clicking &#39;yes.&#39;
        </div>
      </Modal>
    );
  }

  resetPassword(user_id) {
    this.props.toggleLoaderOn();
    this.props.resetPassword({ user_id, organization_id: 0 }) // organization_id zero is parking tree
      .then(() => {
        this.setState({ confirmPasswordReset: false, user_id: undefined });
        this.props.addAlert('success', 'The users password has been reset.');
      })
      .catch(error => {
        console.log(JSON.stringify(error)); // eslint-disable-line
      })
      .finally(() => {
        this.props.toggleLoaderOff();
      });
  }

}

export default connect(select, {
  getUsersByOrganization,
  toggleLoaderOn,
  toggleLoaderOff,
  resetPassword,
  addAlert,
})(StaffList);