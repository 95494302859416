import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@mui/material';

import { 
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@timber-rider/parkingtree-client-common';

import { Modal } from '@timber-rider/parkingtree-client-common';

import { getRoles } from 'view/private/Body/Staff/actions';
import { toggleLoaderOn, toggleLoaderOff } from 'view/actions';

import styles from 'view/private/Body/Staff/RolesList/index.module.css';

const select = (state) => ({
  roleList: state.staff.roles,
});

export class RolesList extends Component {

  static propTypes = {
    toggleLoaderOn: PropTypes.func.isRequired,
    toggleLoaderOff: PropTypes.func.isRequired,
    getRoles: PropTypes.func.isRequired,
    roleList: PropTypes.array.isRequired,
  };

  state = {
    loading: false,
    confirmDelete: false,
    roleDeleteId: -1
  };

  componentDidMount() {
    this.props.toggleLoaderOn();
    this.props.getRoles()
      .then(() => {
        this.props.toggleLoaderOff();
      });
  }

  render() {
    const { loading } = this.state;
    const { roleList } = this.props;
    return (
      <div className={styles.container}>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant='contained'
            onClick={() => this.props.history.push('/staff/stafflist')}
            style={{ marginRight: '10px' }}
          >
            manage staff
          </Button>
          <Button
            variant='contained'
            onClick={() => this.props.history.push('/staff/rolesmanage')}
          >
            add role
          </Button>
        </div>
        <SmartTable
          idKey='role_id'
          title='Parking Tree Roles'
          rows={roleList}
          loading={loading}
          columns={[
            {
              key: 'role_name',
              title: 'Role',
              type: 'string',
            },
          ]}
          actions={[
            {
              displayName: 'Edit Role',
              type: 'menu',
              onSelect: row => this.props.history.push(`/staff/rolesmanage/${row.role_id}`),
            },
            // {
            //   displayName: 'Delete Role',
            //   type: 'menu',
            //   onSelect: row => this.setState({ confirmDelete: true, roleDeleteId: row.role_id }),
            //   showIf: () => true,
            // },
          ]}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
        {this.confirmDelete()}
      </div>
    );
  }

  confirmDelete() {
    const actionButtons = [
      {
        label: 'OK',
        action: () => this.setState({ confirmDelete: false }),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDelete: false })}
        show={this.state.confirmDelete}
        title='Confirm Delete'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          Are you sure you want to delete this Role?
        </div>
      </Modal>
    );
  }
}

export default connect(select, {
  getRoles,
  toggleLoaderOn,
  toggleLoaderOff,
})(RolesList);