import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import PropertyInfoGroupForm from 'utils/SmartForm/groups/PropertyInfoGroup/PropertyInfoGroupForm/index';

//import styles from 'utils/SmartForm/groups/PropertyInfoGroup/index.module.css';

export class PropertyAddressGroup extends Component {

  static propTypes = {
    nextStep: PropTypes.func.isRequired,
    buildProperty: PropTypes.func.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };

  state = {
    propertyObj: this.props.propertyObj,
  };

  render() {
    const { propertyObj } = this.state;
    return (
      <SmartFormProvider> 
        <PropertyInfoGroupForm 
          nextStep={this.props.nextStep} 
          propertyObj={propertyObj}
          buildProperty={this.props.buildProperty}
        /> 
      </SmartFormProvider>
    );
  }
}

  
export default withRouter(connect(null, null)(PropertyAddressGroup));
