import { cloneDeep } from 'lodash';

import { propertyList, propertyEmployees, enforcementList, permitTypes, } from 'view/private/Body/Property/data';

import {
  GET_PROPERTYS,
} from 'view/private/Body/Property//constants';

const initialState = {
  propertyList,
  propertyEmployees,
  enforcementList,
  permitTypes,
};

function PropertysReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_PROPERTYS: {
      return newState;
    }

    default:
      return state;
  }
}

export default PropertysReducer;