/*
<div className={styles.Permit_recurring}>
              <ToggleInput
                inputName={`permitRecurring~${index}`}
                defaultChecked={true}
                isRequired={false}
                labelText='Recurring'
                labelPlacement='start'
              />
            </div>
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import { Modal } from '@timber-rider/parkingtree-client-common';

import NumericInput from 'utils/SmartForm/components/NumericInput';
import TextInput from 'utils/SmartForm/components/TextInput';
//import ToggleInput from 'utils/SmartForm/components/ToggleInput';
import DropdownInput from 'utils/SmartForm/components/DropdownIinput';
//import CurrencyAmountInput from 'utils/SmartForm/components/CurrencyAmountInput';
import CurrencyAmountInput from 'utils/SmartForm/components/NumericInput'; //Fix this
import DatePickerInput from 'utils/SmartForm/components/DatePickerInput';

import { 
  hourRange,
  permitTypes,
  permitDurationType,
  assignmentMethod,
  numberRooms,
  maxPermits,
  quantityLimit,
} from 'utils/SmartForm/groups/constants';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import styles from 'utils/SmartForm/groups/PropertyUnitsGroup/index.module.css';

export class AddUnitForm extends Component {
  
  static propTypes = {
    addUnit: PropTypes.func.isRequired,
    isAddingUnit: PropTypes.bool.isRequired,
    closeAddUnit: PropTypes.func.isRequired,
    currentUnit: PropTypes.object.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };

  state = {
    isBaseFormBuilt: false,
    isPermitsBuilt: false,

    permitList: [],
    currentUnit: {},
    numberRooms,
    hourRange,
    permitTypes,
    permitDurationType,
    assignmentMethod,
  };

  static contextType = SmartFormContext;

  componentDidUpdate() { 
    const { isPermitsBuilt, isBaseFormBuilt } = this.state;
    const { isAddingUnit, propertyObj } = this.props;
    const { Resident } = propertyObj;
    if (!isPermitsBuilt && !isBaseFormBuilt && isAddingUnit) {
      this.setState(
        { isBaseFormBuilt: true },
        () => {
          const list = cloneDeep(this.context.inputList);
          for (const [key, value] of Object.entries(Resident)) {
            list.forEach(input => {
              if (input.inputName === key) {
                input.val = value;
                input.update = true;  
              }
            });
          }
          this.context.updateInputList(list);
          this.forceUpdate();
        }
      );
    }

    if (!isPermitsBuilt && isBaseFormBuilt && isAddingUnit) {
      const list = cloneDeep(this.context.inputList);
      if (list.length > 6) {
        const { permits } = Resident;
        list.forEach(input => {
          permits.forEach(permit => {
            for (const [key, value] of Object.entries(permit)) {
              if (input.inputName === key && value !== null) {
                input.val = value;
                input.update = true;
              }
            }
          });
        });
        this.context.updateInputList(list);
        this.setState({ isPermitsBuilt: true });
      }
    }
  }

  
  render() {
    const { numberRooms, } = this.state;
    const minStartDate = new Date();
    return (
      <Modal
        show={this.props.isAddingUnit}
        title='add unit'
        maxWidth='md'
        onCloseModal={() => {
          this.setState(
            { isPermitsBuilt: false, isBaseFormBuilt: false },
            () => {
              this.context.clearSmartForm();
              this.props.closeAddUnit();
            }
          );
        }}
        actionButtons={[
          {
            label: 'cancel',
            action: () => {
              this.setState(
                { isPermitsBuilt: false, isBaseFormBuilt: false },
                () => {
                  this.context.clearSmartForm();
                  this.props.closeAddUnit();
                }
              );
            }
          },
          {
            label: 'save unit',
            action: () => this.props.addUnit(),
            buttonType: 'contained',
          },
        ]}
      >
        <div className={styles.container}>
          <div className={styles.unit}>
            <TextInput
              inputName='Unit'
              isRequired={true}
              labelText='Unit'
              autoComplete={'off'}
              maxLength={50}
              style={{ width: '250px' }}
            />
          </div>
          <div className={styles.leaseExpiration}>
            <DatePickerInput
              inputName='ExpirationDate'
              isRequired={true}
              labelText='Lease Expiration'
              inputFullWidth
              minDate={minStartDate}
              style={{ width: '250px' }}
            />
          </div>
          <div className={styles.grandFatherExpiration}>
            <DatePickerInput
              inputName='GrandfatherExpiration'
              isRequired={true}
              labelText='Grandfather Expiration'
              inputFullWidth
              minDate={minStartDate}
              style={{ width: '250px' }}
            />
          </div>
          <div className={styles.numOfRooms}>
            <DropdownInput
              inputName='Bedrooms'
              isRequired={true}
              labelText='Number of Rooms'
              style={{ width: '250px' }}
              options={numberRooms.map(permit => ({
                value: permit.id,
                display: permit.name,
              }))}
              inputFocus={true}
            />
          </div>
          <div>
            <DropdownInput
              inputName='permitLimit'
              labelText='Permit Limit'
              isRequired={true}
              style={{ width: '250px' }}
              options={maxPermits.map(limit => ({
                value: limit.id,
                display: limit.name,
              }))}
              onSelection={value => {
                const limit = Number(value);
                if (limit > 0) {
                  const permitList = [];
                  for (let i = 0; i < limit; ++i) {
                    permitList.push({ id: i });
                  }
                  this.setState(
                    { permitList },
                    () => {
                      const inputList = cloneDeep(this.context.inputList);
                      const regularInputs = inputList.filter(input => !input.inputName.includes('~'));
                      const allPermitInputs = inputList.filter(input => input.inputName.includes('~'));
                      const totalPermitInputs = 11 * limit;
                      const remainingPermitInputs = allPermitInputs.filter((input, index) => index < totalPermitInputs);
                      this.context.updateInputList([ ...regularInputs, ...remainingPermitInputs ]);
                      this.forceUpdate();
                    }
                  );
                }
                value = { value };
              }}
            />
          </div>
          <div className={styles.maxNumPermitsPerUnit}>
            <DropdownInput
              inputName='MaxPermits'
              labelText='Max # Permits/Unit'
              isRequired={true}
              style={{ width: '250px' }}
              options={maxPermits.map(limit => ({
                value: limit.id,
                display: limit.name,
              }))}
            />
          </div>
        </div>
        <div>
          {this.renderPermits()}
        </div>
      </Modal>
    );
  }

  renderPermits() {
    const { permitList } = this.state;
    const { isAddingUnit } = this.props;
    const { SpacesFiles } = this.props.propertyObj.Resident;
    const minStartDate = new Date();
    const limit = permitList.length;
    let permitSpaces = [];
    let spaces = [];

    if (isAddingUnit && limit > 0) {
      return permitList.map((permit, index) => {
        
        const methodInput = this.context.inputList.find(input => input.inputName === `assignmentMethod~${index}`);
        let isMethodByUnit = true;
        if (methodInput) {
          const permitType = this.context.inputList.find(input => input.inputName === `permitType~${index}`);
          if (permitType) {
            isMethodByUnit = true;
            permitSpaces = SpacesFiles[permitType.val];
            if (permitSpaces.length > 0) {
              spaces = permitSpaces.map(item => {
                return {
                  value: item.Space,
                  display: item.Space,
                };
              });
            }
          }
        }
        return (
          <div className={styles.Permit_container} key={index}>
            <div className={styles.Permit_permitTitle}>Permit ({index + 1} of {limit})</div>
            <div className={styles.Permit_permitType}>
              <DropdownInput
                inputName={`permitType~${index}`}
                isRequired={true}
                labelText='Permit Type'
                style={{ width: '250px' }}
                options={permitTypes.map(permit => ({
                  value: permit.id,
                  display: permit.name,
                }))}
                inputFocus={true}
              />
            </div>
            <div className={styles.Permit_cost}>
              <CurrencyAmountInput
                inputName={`permitCost~${index}`}
                isRequired={true}
                labelText='Cost'
                autoComplete={'off'}
                style={{ width: '250px' }}
              />
            </div>
            <div className={styles.Permit_durationType}>
              <DropdownInput
                inputName={`permitDurationType~${index}`}
                isRequired={true}
                labelText='Duration Type'
                style={{ width: '250px' }}
                options={permitDurationType.map(duration => ({
                  value: duration.id,
                  display: duration.name,
                }))}
              />
            </div>
            <div className={styles.Permit_duration}>
              <NumericInput
                inputName={`permitDuration~${index}`}
                isRequired={true}
                labelText='Permit Duration'
                autoComplete={'off'}
                maxLength={2}
                style={{ width: '250px' }}
              />
            </div>
            <div className={styles.Permit_assignMethod}>
              <DropdownInput
                inputName={`assignmentMethod~${index}`}
                labelText='Assignment Method'
                isRequired={true}
                style={{ width: '250px' }}
                options={assignmentMethod.map(range => ({
                  value: range.id,
                  display: range.name,
                }))}
              />
            </div>
            <div className={styles.Permit_quantityLimit}>
              <DropdownInput
                inputName={`quantityLimit~${index}`}
                isRequired={true}
                labelText='Quantity Limit'
                style={{ width: '250px' }}
                options={quantityLimit.map(limit => ({
                  value: limit.id,
                  display: limit.name,
                }))}
              />
            </div>
            <div className={styles.permitGrandfatherExpiration}>
              <DatePickerInput
                inputName={`permitGrandfatherExpiration~${index}`}
                isRequired={true}
                labelText='Grandfather Expiration'
                inputFullWidth
                minDate={minStartDate}
                style={{ width: '250px' }}
              />
            </div>
            <div className={styles.Permit_GrandfatherCost}>
              <CurrencyAmountInput
                inputName={`GrandfatherCost~${index}`}
                isRequired={true}
                labelText='Grandfather Cost'
                autoComplete={'off'}
                style={{ width: '250px' }}
              />
            </div>
            <div className={styles.Permit_assignedSpace}>
              {isMethodByUnit
                ?
                <DropdownInput
                  inputName={`assignedSpace~${index}`}
                  isRequired={true}
                  labelText='Assigned Space'
                  style={{ width: '250px' }}
                  options={spaces}
                />
                :
                <TextInput
                  inputName={`assignedSpace~${index}`}
                  isRequired={false}
                  labelText='Resident Note'
                  autoComplete={'off'}
                  style={{ width: '250px' }}
                />
              }
            </div>
            <div className={styles.Permit_recurring}>
              permitRecurring~
            </div>
            <div className={styles.Permit_residentNote}>
              <TextInput
                inputName={`ResidentNote~${index}`}
                isRequired={false}
                labelText='Resident Note'
                autoComplete={'off'}
                maxLength={50}
                style={{ width: '510px' }}
              />
            </div>
          </div>
        );
      });
    }
  }

}

export default AddUnitForm;