import React, { Component } from 'react';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import PermitTypeManageForm from 'view/private/Body/Utilities/PermitTypes/PermitTypeManage//PermitTypeManageForm';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

import styles from 'view/private/Body/Utilities/PermitTypes/PermitTypeManage/index.module.css';

export class PropertyPermitTypeManage extends Component {

  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay
        show={loading}
        width='100%'
      >
        <div className={styles.container}>
          <div className={styles.title}>Add Permit Type</div>
          <div className={styles.permitName}>
            <SmartFormProvider>
              <PermitTypeManageForm />
            </SmartFormProvider>
          </div>
        </div>
      </LoadingOverlay>
    );
  }

}

export default PropertyPermitTypeManage;