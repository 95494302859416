import { cloneDeep } from 'lodash';

import {
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  GET_ROLES,
  GET_ROLES_PERMISSIONS,
  GET_USERS_BY_ORG,
  GET_PERMISSIONS,
  CREATE_ROLE,
} from 'view/private/Body/Staff/constants';

const initialState = {
  roles: [],
  permissions: [],
  staffList: [],
  user: {},
};

function StaffReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_USER: {
      newState.user = action.payload.data;
      return newState;
    }
    
    case CREATE_USER: {
      newState.user = {};
      return newState;
    }

    case UPDATE_USER: {
      newState.user = {};
      return newState;
    }
    
    case GET_ROLES: {
      newState.roles = action.payload.data;
      return newState;
    }

    case GET_ROLES_PERMISSIONS: {
      return newState;
    }

    case GET_USERS_BY_ORG: {
      const users = action.payload.data;
      users.forEach(user => {
        user.fullName = `${user.first_name} ${user.last_name}`;
      });
      newState.staffList = users;
      return newState;
    }

    case GET_PERMISSIONS: {
      newState.permissions = action.payload.data;
      return newState;
    }

    case CREATE_ROLE: {
      return newState;
    }

    default:
      return state;
  }
}

export default StaffReducer;