import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import { Button } from '@mui/material';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import TextInput from 'utils/SmartForm/components/TextInput';
import BasicStreetInput from 'utils/SmartForm/components/BasicStreetInput';
import CityInput from 'utils/SmartForm/components/CityInput';
import StateInput from 'utils/SmartForm/components/StateInput';
import PostalCodeInput from 'utils/SmartForm/components/PostalCodeInput';
import PhoneInput from 'utils/SmartForm/components/PhoneInput';
import EmailInput from 'utils/SmartForm/components/EmailInput';

import { PREV, NEXT } from 'utils/SmartForm/groups/constants';

import styles from 'utils/SmartForm/groups/PropertyInfoGroup/PropertyInfoGroupForm/index.module.css';

export class PropertyAddressGroupForm extends Component {

  static propTypes = {
    nextStep: PropTypes.func.isRequired,
    buildProperty: PropTypes.func.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };
  
  state = {
    isLoading: false,
    isFormBuilt: false,
  };

  componentDidMount() {
    const { propertyObj } = this.props;
    if (!this.state.isFormBuilt && !isEmpty(propertyObj.PropertyInfo)) {
      const list = cloneDeep(this.context.inputList);
      if (list && list.length === 11) {
        list.forEach(input => {
          for (const [key, value] of Object.entries(propertyObj.PropertyInfo)) {
            if (input.inputName === key && value !== null) {
              input.val = value;
              input.update = true;
            }
          }
        });
        this.setState(
          { isFormBuilt: true },
          () => {
            this.context.updateInputList(list);
            this.forceUpdate();
          });
      }
    }
  }

  static contextType = SmartFormContext;

  render() {
    return (
      <div style={{ textAlign: 'center', width: '70%' }}>
        <div className={styles.Address_pageTitle}>Property Address</div>
        <div className={styles.Address_container}>
          <div className={styles.Address_propertyName}>
            <TextInput
              inputName='PropertyName'
              isRequired={true}
              labelText='Property Name'
              inputFocus={true}
              autoComplete={'off'}
              style={{ width: '100%' }}
            />
          </div>
          <div className={styles.Address_address}>
            <BasicStreetInput
              inputName='Address1'
              isRequired={true}
              labelText='Street Address'
              style={{ width: '100%' }}
            />
          </div>
          <div className={styles.Address_unit}>
            <BasicStreetInput
              inputName='unit'
              isRequired={false}
              labelText='Unit'
            />
          </div>
          <div className={styles.Address_city}>
            <CityInput
              inputName='City'
              isRequired={true}
              labelText='City'
              inputFullWidth
            />
          </div>
          <div className={styles.Address_state}>
            <StateInput
              inputName='State'
              isRequired={true}
              labelText='State'
              style={{ width: '200px' }}
            />            
          </div>
          <div className={styles.Address_postalCode}>
            <PostalCodeInput
              inputName='MailingPostalCode'
              isRequired={true}
              labelText='Postal Code'
              style={{ width: '200px' }}
            />
          </div>
          <div className={styles.Address_phone}>
            <PhoneInput
              inputName='PhoneNumber'
              isRequired={true}
              labelText='Phone Number'
              autoComplete={'off'}
            />
          </div>
        </div>

        <div className={styles.Address_pageTitle}>Property Administrator</div>
        <div className={styles.Admin_container}>
          <div className={styles.Admin_firstName}>
            <TextInput
              inputName='AdminFirstName'
              isRequired={true}
              labelText='First Name'
              autoComplete={'off'}
              inputFullWidth
            />
          </div>
          <div className={styles.Admin_lastName}>
            <TextInput
              inputName='AdminLasttName'
              isRequired={true}
              labelText='Last Name'
              inputFullWidth
            />
          </div>
          <div className={styles.Admin_phone}>
            <PhoneInput
              inputName='AdminPhoneNumber'
              isRequired={true}
              labelText='Phone Number'
              autoComplete={'off'}
              style={{ width: '200px' }}
            />
          </div>
          <div className={styles.Admin_email}>
            <EmailInput
              inputName='AdminEmail'
              isRequired={true}
              labelText='Email Address'
              autoComplete={'off'}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        <div className={styles.Admin_buttons}>
          <Button
            variant='contained'
            onClick={() => this.addInfo(PREV)}
          >
            previous
          </Button>&nbsp;
          <Button
            variant='contained'
            onClick={() => this.addInfo(NEXT)}
          >
            next
          </Button>
        </div>
      </div>
    );
  }

  addInfo() {
    const inputList = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(inputList);
    this.context.updateInputList(validationObject.inputList);
    this.forceUpdate();
    if (validationObject.isValid) {
      const propertyObj = cloneDeep(this.props.propertyObj);
      const result = this.context.prepInputList(validationObject.inputList);
      propertyObj.PropertyInfo = result;
      this.props.buildProperty(propertyObj);
      this.props.nextStep();
    }
  }

}

export default connect(null, null)(PropertyAddressGroupForm);