import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Dropdown } from '@timber-rider/parkingtree-client-common';

export class DropdownInput extends Component {

  static propTypes = {
    inputName: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputFocus: PropTypes.bool,
    labelText: PropTypes.string,
    inputFullWidth: PropTypes.bool,
    autoComplete: PropTypes.string, // expected val is 'on' or 'off'
    className: PropTypes.string,
    style: PropTypes.object,

    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      display: PropTypes.node,
      props: PropTypes.object,
    })).isRequired,
  };

  state = {
    inputValue: '',
    inputIndex: undefined,
    isError: undefined,
    helperMsg: ''
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { inputName, isRequired } = this.props;
    const inputIndex = this.context.addInput(this.context.inputList, inputName, isRequired);
    this.setState({ inputIndex });
  }

  componentDidUpdate(prevProps, prevState) {
    const inputValue = this.context.inputList[this.state.inputIndex].inputValue;
    if (prevState.inputValue !== inputValue) {
      this.setState({ inputValue });
    }
  }

  componentWillUnmount() {
    this.context.removeInput(this.context.inputList, this.props.inputName);
  }

  render() {
    const {
      inputName, labelText, isDisabled, isReadOnly,
      inputFullWidth, className, style, options,
    } = this.props;

    const { inputValue, isError, helperMsg } = this.state;

    return (
      <Dropdown
        label={labelText}
        value={inputValue}
        errorText={(helperMsg) || this.context.helperTextManage(inputName)}
        onChange={value => this.inputChange({ target: { value } })}
        options={options}
        variant='outlined'
        FormControlProps={{
          fullWidth: inputFullWidth,
          error: isError || this.context.helperErrorManage(inputName),
          disabled: isDisabled,
          className,
          style,
        }}
        SelectProps={{
          inputProps: { readOnly: isReadOnly },
        }}
      />
    );
  }

  inputChange = e => {
    let inputValue = e.target.value;
    const { inputName } = this.props;

    if (inputValue.length === 0) {
      inputValue = undefined;
    }

    this.setState(
      { inputValue },
      this.context.updateInput(this.context.inputList, inputName, inputValue)
    );
  };

}

export default DropdownInput;