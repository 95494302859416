import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from 'view/private/Body/Settings/index.module.css';

const select = (state) => ({
  userDetails: state.session.userDetails,
});

export class Settings extends Component {
  
  static propTypes = {
    userDetails: PropTypes.object.isRequired,
  };

  render() {
    const { name, email, mobile_phone, role } = this.props.userDetails;
    return (
      <div>
        <div className={styles.title}>User Settings</div>
        <div className={styles.container}>
          <div className={styles.nameLabel}>Name:</div><div className={styles.name}>{name}</div>
          <div className={styles.roleLabel}>Role:</div><div className={styles.role}>{role}</div>
          <div className={styles.emailLabel}>Email:</div><div className={styles.email}>{email}</div>
          <div className={styles.phoneLabel}>Phone</div><div className={styles.phone}>{mobile_phone}</div>
        </div>
      </div>
    );
  }
}

export default connect(select, {})(Settings);