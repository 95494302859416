import React, { Component } from 'react';

import { Button } from '@mui/material';

import PermitTypeList from 'view/private/Body/Utilities/PermitTypes/PermitTypeList/index';
import PermitTypeManage from 'view/private/Body/Utilities/PermitTypes/PermitTypeManage/index';

import styles from 'view/private/Body/Utilities/index.module.css';

export class Utilities extends Component {

  state = {
    utilNum: -1,
  };

  render() {
    let currentUtility;
    switch (this.state.utilNum) {
      case 0: 
        currentUtility = <PermitTypeList updateUtility={utilNum => this.changeUtility(utilNum)} />;
        break;
      case 1: 
        currentUtility = <PermitTypeManage />;
        break;
      case 2:
      default:
        break;
    }
    return (
      <div className={styles.container}>
        <div className={styles.navigation}>
          <Button
            variant='outlined'
            style={{ color: '#0EC47E', borderColor: '#0EC47E' }}
            onClick={() => this.changeUtility(0)}
          >
            permit types
          </Button>
        </div>
        <div className={styles.utilities}>{currentUtility}</div>
      </div>
    );
  }

  changeUtility(utilNum) {
    this.setState({ utilNum });
  }

}

export default Utilities;