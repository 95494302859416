export const INITIALIZE_STORE = 'view/INITIALIZE_STORE';

export const BROWSER_WARNING = 'view/BROWSER_WARNING';
export const MOBILE_WARNING = 'view/MOBILE_WARNING';
export const TOKEN_VALIDATE = 'view/TOKEN_VALIDATE';

export const LOGIN = 'view/LOGIN';

export const CONFIRM_OTP_TOKEN = 'view/CONFIRM_OTP_TOKEN';
export const CONFIRM_OTP = 'view/CONFIRM_OTP';
export const CREATE_PASSWORD = 'view/CREATE_PASSWORD';
export const RESET_PASSWORD = 'view/RESET_PASSWORD';

export const TOGGLE_SIDENAV = 'view/TOGGLE_SIDENAV';

export const TOGGLE_LOADER_ON = 'view/TOGGLE_LOADER_ON';
export const TOGGLE_LOADER_OFF = 'view/TOGGLE_LOADER_OFF';

export const PASSWORD_REQUIREMENTS = [
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^.{8,1000}$',
    PasswordRequirementId: 3,
    RequirementDescription: 'Must be at least eight characters long.',
    SecurityLevel: 2,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*\\d).{1,1000}$',
    PasswordRequirementId: 4,
    RequirementDescription: 'Must contain a numeric character.',
    SecurityLevel: 3,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*[^0-9a-zA-Z]).{1,1000}$',
    PasswordRequirementId: 5,
    RequirementDescription: 'Must contain a special character.',
    SecurityLevel: 4,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*[A-Z]).{1,1000}$',
    PasswordRequirementId: 6,
    RequirementDescription: 'Must contain an uppercase letter.',
    SecurityLevel: 1,
    SecurityPoints: 1,
  },
  {
    ApplicationPasswordRequirements: null,
    ChangeState: 0,
    PasswordRegularExpression: '^(?=.*[a-z]).{1,1000}$',
    PasswordRequirementId: 7,
    RequirementDescription: 'Must contain a lowercase letter.',
    SecurityLevel: 1,
    SecurityPoints: 1,
  }
];