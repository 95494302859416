import { POSTrequest } from 'utils/helpers/api_handler';
import {
  INITIALIZE_STORE,
  LOGIN,
  CONFIRM_OTP_TOKEN,
  CONFIRM_OTP,
  CREATE_PASSWORD,
  RESET_PASSWORD,
  BROWSER_WARNING,
  MOBILE_WARNING,
  TOGGLE_SIDENAV,
  TOGGLE_LOADER_ON,
  TOGGLE_LOADER_OFF,
} from 'view/constants';
  
export function userLogin(body) {
  const response = POSTrequest('/auth/login', body);
  return {
    type: LOGIN,
    payload: response,
  };
}

export function userLogout() {
  return {
    type: INITIALIZE_STORE,
  };
}

export function confirmOTPToken(otpToken) {
  const response = POSTrequest('/auth/confirm-otp-token', { otpToken });
  return {
    type: CONFIRM_OTP_TOKEN,
    payload: response,
  };
}

export function confirmOTP(otp) {
  const response = POSTrequest('/auth/confirm-otp', { otp });
  return {
    type: CONFIRM_OTP,
    payload: response,
    meta: otp,
  };
}

export function createPassword(body) {
  const response = POSTrequest('/auth/create-password', body);
  return {
    type: CREATE_PASSWORD,
    payload: response,
  };
}

export function resetPassword(body) {
  const response = POSTrequest('/auth/reset-password', body);
  return {
    type: RESET_PASSWORD,
    payload: response,
  };
}


export function setBrowserWarning() {
  return {
    type: BROWSER_WARNING,
  };
}

export function setMobileWarning() {
  return {
    type: MOBILE_WARNING,
  };
}

export function clearStore() {
  return {
    type: INITIALIZE_STORE
  };
}

export function toggleSidenav(isOpen) {
  return {
    type: TOGGLE_SIDENAV,
    meta: { isOpen }
  };
}

export function toggleLoaderOn() {
  return {
    type: TOGGLE_LOADER_ON,
  };
}

export function toggleLoaderOff() {
  return {
    type: TOGGLE_LOADER_OFF,
  };
}