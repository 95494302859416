import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { withRouter } from 'react-router-dom';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';

import { Button } from '@mui/material';

import TextInput from 'utils/SmartForm/components/TextInput';
import BasicStreetInput from 'utils/SmartForm/components/BasicStreetInput';
import CityInput from 'utils/SmartForm/components/CityInput';
import StateInput from 'utils/SmartForm/components/StateInput';
import PostalCodeInput from 'utils/SmartForm/components/PostalCodeInput';
import EmailInput from 'utils/SmartForm/components/EmailInput';
import PhoneInput from 'utils/SmartForm/components/PhoneInput';

import styles from 'view/private/Body/Enforcement/EnforcementManage/EnforcementManageForm/index.module.css';

const select = (state) => ({
  enforcementEmployees: state.enforcement.enforcementEmployees,
});

export class EnforcementManageForm extends Component {

  static propTypes = {
    enforcementEmployees: PropTypes.array.isRequired,
  };

  state = {
    isLoading: false,
    confirmDelete: false,
    deleteEmployeeId: undefined,
  };

  static contextType = SmartFormContext;

  render() {
    const { EnforcementId } = this.props.match.params;
    const pageLabel = EnforcementId ? 'MANAGE' : 'ADD';
    
    return (
      <React.Fragment>
        <div className={styles.companyName}>
          <TextInput
            inputName='CompanyName'
            isRequired={true}
            labelText='Company Name'
            inputFocus={true}
            autoComplete={'off'}
            style={{ width: '100%' }}
          />
        </div>
        <div className={styles.address1}>
          <BasicStreetInput
            inputName='Address1'
            isRequired={true}
            labelText='Street Address'
            style={{ width: '100%' }}
          />
        </div>
        <div className={styles.address2}>
          <BasicStreetInput
            inputName='unit'
            isRequired={false}
            labelText='Unit'
          />
        </div>
        <div className={styles.city}>
          <CityInput
            inputName='City'
            isRequired={true}
            labelText='City'
            inputFullWidth
          />
        </div>
        <div className={styles.state}>
          <StateInput
            inputName='State'
            isRequired={true}
            labelText='State'
            style={{ width: '200px' }}
          />
        </div>
        <div className={styles.zip}>
          <PostalCodeInput
            inputName='MailingPostalCode'
            isRequired={true}
            labelText='Postal Code'
          />
        </div>
        <div className={styles.email}>
          <EmailInput
            inputName='EmailAddress'
            isRequired={true}
            labelText='Email'
            style={{ width: '100%' }}
            autoComplete={'off'}
          />
        </div>
        <div className={styles.phone}>
          <PhoneInput
            inputName='PhoneNumber'
            isRequired={true}
            labelText='Phone Number'
            autoComplete={'off'}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={() => this.props.history.push('/enforcement-list')}
          >
            cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => this.manageEnforcement()}
          >
            {pageLabel} company
          </Button>
        </div>
      </React.Fragment>
    );
  }

  manageEnforcement() {
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);
    if (validationObject.isValid) {
      const inputs = this.context.prepInputList(validationObject.inputList);
      alert(JSON.stringify(inputs));
      // this.props.history.push('/enforcement-list')}
    }
    else {
      this.context.updateInputList(validationObject.inputList);
      this.forceUpdate();
    }
  }
}

export default withRouter(connect(select, null)(EnforcementManageForm));