export const enforcementList = [
  { EnforcementId: 0, EnforcementName: 'AAA Towing', Address1: '123 Hartford Ln', Address2: '', City: 'Conroe', State: 'TX', ZIP: '77341', Phone: '(614) 775-2534', email: 'aaa@a.com' },
  { EnforcementId: 1, EnforcementName: 'Triple AAA Towing', Address1: '812 N Robson', Address2: 'Suite A', City: 'Mesa', State: 'AZ', ZIP: '85201', Phone: '(480) 432-8599', email: 'triple@a.com' },
  { EnforcementId: 2, EnforcementName: 'Lift Towing', Address1: '1432 Emerald Ln', Address2: 'Apt 323', City: 'Mesa', State: 'AZ', ZIP: '85234', Phone: '(602) 323-5478', email: 'tow@lift.com' },
  { EnforcementId: 3, EnforcementName: 'Autocare Towing', Address1: '1570 Broadway', Address2: '', City: 'Chandler', State: 'AZ', ZIP: '85204', Phone: '(602) 561-5768', email: 'tow@autocare.com' },
];

export const enforcementEmployees = [
  { employeeId: 0, firstName: 'Rick', lastName: 'Sanchez', fullName: 'Rick Sanchez', roleType: 'Admin' },
  { employeeId: 1, firstName: 'Morty', lastName: 'Smith', fullName: 'Morty Smith', roleType: 'Office' },
  { employeeId: 2, firstName: 'Beth', lastName: 'Smith', fullName: 'Beth Smith', roleType: 'Office' },
  { employeeId: 3, firstName: 'Summer', lastName: 'Smith', fullName: 'Summer Smith', roleType: 'Office' },
  { employeeId: 4, firstName: 'Jerry', lastName: 'Smith', fullName: 'Jerry Smith', roleType: 'Office' },
];

export const permissions = {
  EditRole: true,
  EditStaff: true,
  EditProperty: true,
  EditEnforcement: true,
};