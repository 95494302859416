import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import { Button } from '@mui/material';

import { 
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@timber-rider/parkingtree-client-common';

import AddUnit from 'utils/SmartForm/groups/PropertyUnitsGroup/AddUnit';
import EditUnit from 'utils/SmartForm/groups/PropertyUnitsGroup/EditUnit';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
import { PREV, NEXT } from 'utils/SmartForm/groups/constants';


export class PropertyUnitsGroupForm extends Component {
  
  static propTypes = {
    prevStep: PropTypes.func.isRequired,
    propertyObj: PropTypes.object.isRequired,
    buildProperty: PropTypes.func,
  };

  state = {
    isLoading: false,
    isAddingUnit: false,
    isEditingUnit: false,
    
    unitList: [],
    currentUnit: {},
    confirmDelete: false,
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { Units } = this.props.propertyObj;
    if (Units && Units.length > 0) {
      this.setState({ unitList: Units });
    }
  }

  render() {
    const { isLoading, unitList } = this.state;
    return (
      <div style={{ width: '90%', textAlign: 'center' }}>
        <>
          <div style={{ width: '100%', textAlign: 'right', paddingTop: '10px' }}>
            <Button
              variant='contained'
              onClick={() => this.setState({ isAddingUnit: true })}
              style={{ marginLeft: '10px' }}
            >
              add unit
            </Button>
          </div>
          <SmartTable
            idKey='key'
            title='Property Units'
            rows={unitList}
            loading={isLoading}
            tableSettings={{ rowsPerPage: 50 }}
            columns={[
              {
                key: 'Unit',
                title: 'Unit',
                type: 'string',
              },
              {
                key: 'ExpirationDate',
                title: 'Lease Expiration',
                type: 'date',
              },
              {
                key: 'GrandfatherExpiration',
                title: 'Grandfather Expiration',
                type: 'date',
              },
              {
                key: 'Bedrooms',
                title: 'Bedrooms',
                type: 'string',
              },
              {
                key: 'MaxPermits',
                title: 'Max Permits',
                type: 'string',
              },
            ]}
            actions={[
              {
                displayName: 'Edit Unit',
                type: 'menu',
                onSelect: row => this.setState({ isEditingUnit: true, currentUnit: row }),
              },
              {
                displayName: 'Delete Unit',
                type: 'menu',
                onSelect: row => alert(row),
                showIf: () => true,
              },
            ]}
          >
            <TableToolbar />
            <TableContainer maxHeight='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>

          <div style={{ paddingTop: '20px', paddingBottom: '10px' }}>
            <Button
              variant='contained'
              onClick={() => this.addUnits(PREV)}
            >
              previous
            </Button>&nbsp;
            <Button
              variant='contained'
              onClick={() => this.addUnits(NEXT)}
            >
              create property
            </Button>
          </div>
        </>

        <AddUnit 
          isAddingUnit={this.state.isAddingUnit}
          closeAddUnit={() => {
            this.setState({ isAddingUnit: false });
          }}
          currentUnit={this.state.currentUnit}
          propertyObj={this.props.propertyObj}
          addUnit={() => this.addUnit()}
        />

        <EditUnit 
          isEditingUnit={this.state.isEditingUnit}
          closeEditUnit={() => {
            this.setState({ isEditingUnit: false });
          }}
          currentUnit={this.state.currentUnit}
          propertyObj={this.props.propertyObj}
          editUnit={() => this.editUnit()}
        />
      </div>
    );
  }

  addUnit() {
    const unitList = cloneDeep(this.state.unitList);
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);    
    this.context.updateInputList(validationObject.inputList); //list
    this.forceUpdate();

    if (validationObject.isValid) {
      const regularInputs = list.filter(input => !input.inputName.includes('~'));
      const permitInputs = list.filter(input => input.inputName.includes('~'));
      const limitInput = regularInputs.find(input => input.inputName === 'permitLimit');
      const limit = Number(limitInput.val);
      const permits = [];
      for (let i = 0; i < limit; ++i) {
        const specificPermit = permitInputs.filter(input => input.inputName.includes(i));
        const permit = this.context.prepInputList(specificPermit);
        permits.push(permit);
      }
      const unit = this.context.prepInputList(regularInputs);
      unit.ExpirationDate = moment(unit.ExperationDate).format('MM/DD/YYYY');
      unit.GrandfatherExpiration = moment(unit.GrandfatherExpiration).format('MM/DD/YYYY');
      unit.permits = permits;
      unitList.push(unit);
      this.setState({ unitList, isAddingUnit: false });
    }
  }

  editUnit() {
    const list = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(list);    
    this.context.updateInputList(validationObject.inputList); //list
    this.forceUpdate();
    if (validationObject.isValid) {
      const regularInputs = list.filter(input => !input.inputName.includes('~'));
      const permitInputs = list.filter(input => input.inputName.includes('~'));
      const limitInput = regularInputs.find(input => input.inputName === 'permitLimit');
      const limit = Number(limitInput.val);
      const permits = [];
      for (let i = 0; i < limit; ++i) {
        const specificPermit = permitInputs.filter(input => input.inputName.includes(i));
        const permit = this.context.prepInputList(specificPermit);
        permits.push(permit);
      }
      const unit = this.context.prepInputList(regularInputs);
      unit.ExpirationDate = moment(unit.ExperationDate).format('MM/DD/YYYY');
      unit.GrandfatherExpiration = moment(unit.GrandfatherExpiration).format('MM/DD/YYYY');
      unit.permits = permits;
      const unitList = cloneDeep(this.state.unitList);
      const unitIndex = unitList.findIndex(listUnit => listUnit.Unit === unit.Unit);
      unitList[unitIndex] = unit;
      this.setState({ unitList, isEditingUnit: false });
    }
  }

  addUnits(direction) {
    const propertyObj = cloneDeep(this.props.propertyObj);
    propertyObj.Units = this.state.unitList;
    this.props.buildProperty(propertyObj);
    direction === PREV
      ? this.props.prevStep()
      : alert('Create Property!');
  }

}

export default PropertyUnitsGroupForm;