import { cloneDeep } from 'lodash';

import { 
  enforcementList, 
  enforcementEmployees
} from 'view/private/Body/Enforcement/data';

import {
  GET_ENFORCEMENT_COMPANIES,
} from 'view/private/Body/Enforcement/constants';

const initialState = {
  enforcementList,
  enforcementEmployees,
};

function EnforcementReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_ENFORCEMENT_COMPANIES: {
      // newState.roles = roleList
      return newState;
    }

    default:
      return state;
  }
}

export default EnforcementReducer;