import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from '@mui/material';

import { 
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@timber-rider/parkingtree-client-common';

import { Modal } from '@timber-rider/parkingtree-client-common';

// eslint-disable-next-line
import styles from 'view/private/Body/Property/PropertyList/index.module.css';

const select = (state) => ({
  propertyList: state.propertys.propertyList,
});

export class PropertyList extends Component {

  static propTypes = {
    propertyList: PropTypes.array.isRequired,
  };

  state = {
    loading: false,
    confirmDelete: false,
    propertyDeleteId: -1
  };

  componentWillUnmount() {
    // get list
  }

  render() {
    const { loading } = this.state;
    const { propertyList } = this.props;
    return (
      <div className={styles.container}>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant='contained'
            onClick={() => this.props.history.push('/property-management/create')}
          >
            add property
          </Button>
        </div>
        <SmartTable
          idKey='id'
          title='Properties'
          rows={propertyList}
          loading={loading}
          columns={[
            {
              key: 'name',
              title: 'Name',
              type: 'string',
            },
            {
              key: 'Address1',
              title: 'Address',
              type: 'string',
            },
            {
              key: 'City',
              title: 'City',
              type: 'string',
            },
            {
              key: 'State',
              title: 'State',
              type: 'string',
            },
            {
              key: 'Phone',
              title: 'Phone',
              type: 'string',
            },
          ]}
          actions={[
            {
              displayName: 'Manage Enforcement',
              type: 'menu',
              onSelect: row => this.props.history.push(`/property-management/enforcement/${row.id}`),
            },
            {
              displayName: 'Manage Employees',
              type: 'menu',
              onSelect: row => this.props.history.push(`/property-management/employee/${row.id}`),
            },
            {
              displayName: 'Edit Property',
              type: 'menu',
              onSelect: () => this.props.history.push('/property-management/create'),
              //onSelect: row => this.props.history.push('/property-management/create'),
            },
            {
              displayName: 'Delete Property',
              type: 'menu',
              onSelect: row => this.setState({ confirmDelete: true, propertyDeleteId: row.id }),
              showIf: () => true,
            },
          ]}
        >
          <TableToolbar />
          <TableContainer maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
        {this.confirmDelete()}
      </div>
    );
  }

  confirmDelete() {
    const actionButtons = [
      {
        label: 'OK',
        action: () => this.deleteProperty(),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ confirmDelete: false })}
        show={this.state.confirmDelete}
        title='Confirm Delete'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          Are you sure you want to delete this property?
        </div>
      </Modal>
    );
  }

  deleteProperty() {
    this.setState({ confirmDelete: false });
  }

}

export default withRouter(connect(select, null)(PropertyList));