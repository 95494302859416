/* 
<div className={styles.Permits_halfHourFree}>
              <ToggleInput
                inputName={`initialHalfHour~${index}`}
                defaultChecked={true}
                labelText='Initial Half Hour Free?'
                labelPlacement='start'
                autoComplete={'off'}
              />
            </div>
            <div className={styles.Permits_hourlyPermits}>
              <ToggleInput
                inputName={`hourlyPermits~${index}`}
                defaultChecked={true}
                labelText='Hourly Permits?'
                labelPlacement='start'
                autoComplete={'off'}
                formUpdate={() => this.forceUpdate()}
              />
            </div>
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

import { Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { Modal } from '@timber-rider/parkingtree-client-common';

import { SmartFormContext } from 'utils/SmartForm/SmartFormProvider';
//import { parseCSV } from 'utils/SmartForm/groups/PropertyResidentSettingsGroup/helper';
import { parseCSV } from 'utils/SmartForm/groups/PropertyGuestSettingsGroup/helper';

import TextInput from 'utils/SmartForm/components/TextInput';
import DropdownInput from 'utils/SmartForm/components/DropdownIinput';
//import CurrencyAmountInput from 'utils/SmartForm/components/CurrencyAmountInput';
//import ToggleInput from 'utils/SmartForm/components/ToggleInput';
// import from NumericInput not CurrencyAmountInput
import CurrencyAmountInput from 'utils/SmartForm/components/NumericInput';

import { PREV, NEXT } from 'utils/SmartForm/groups/constants';

import styles from 'utils/SmartForm/groups/PropertyGuestSettingsGroup/PropertyGuestSettingsGroupForm/index.module.css';

import { 
  permitLimits,
  guestPermitTypes,
  guestAssignmentMethod,
  activeGuestLimit,
  days,
} from 'utils/SmartForm/groups/constants';

export class PropertyGuestSettingsGroupForm extends Component {

  static propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    buildProperty: PropTypes.func.isRequired,
    propertyObj: PropTypes.object.isRequired,
  };
  
  state = {
    isLoading: false,
    permitList: [],
    spacesFiles: {},
    isValidating: false,
    validationMsg: '',
  };

  static contextType = SmartFormContext;

  componentDidMount() {
    const { Guest } = this.props.propertyObj;
    if (!isEmpty(Guest)) {
      const { permits } = Guest;
      const permitList = [];
      if (permits.length > 0) {
        permits.forEach((permit, index) => {
          permitList.push({ id: index, hasFiles: false });
        });
      }
      const spacesFiles = isEmpty(Guest.SpacesFiles) ? {} : Guest.SpacesFiles;
      this.setState(
        { permitList, spacesFiles },
        () => {
          if (!isEmpty(permits) && permits.length > 0) {
            const list = cloneDeep(this.context.inputList);
            list.forEach(input => {
              for (const [key, value] of Object.entries(Guest)) {
                if (input.inputName === key && value !== null) {
                  input.val = value;
                  input.update = true;
                }
              }
            });
            
            permits.forEach(permit => {
              for (const [key, value] of Object.entries(permit)) {
                list.forEach(input => {
                  if (input.inputName === key) {
                    input.val = value;
                    input.update = true;
                  }
                });
              }
            });
            this.context.updateInputList(list);
            this.forceUpdate();
          }
        }
      );
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.pageTitle}>Guest Settings</div>
        <div className={styles.permitSelection}>
          <DropdownInput
            inputName='permitLimit'
            labelText='Permit Selection'
            isRequired={true}
            style={{ width: '300px' }}
            options={permitLimits.map(limit => ({
              value: limit.id,
              display: limit.name,
            }))}
            onSelection={value => {
              const limit = Number(value);
              if (limit > 0) {
                const permitList = [];
                for (let i = 0; i < limit; ++i) {
                  permitList.push({ id: i, hasFiles: false });
                }
                this.setState(
                  { permitList },
                  () => {
                    const inputList = cloneDeep(this.context.inputList);
                    const regularInputs = inputList.filter(input => !input.inputName.includes('~'));
                    const allPermitInputs = inputList.filter(input => input.inputName.includes('~'));
                    const totalPermitInputs = 10 * limit;
                    const remainingPermitInputs = allPermitInputs.filter((input, index) => index < totalPermitInputs);
                    this.context.updateInputList([ ...regularInputs, ...remainingPermitInputs ]);
                    this.forceUpdate();
                  }
                );
              }
            }}
          />
        </div>
        <div className={styles.permits}>{this.renderPermits()}</div>
        <div className={styles.pageBtns}>
          <Button
            variant='contained'
            onClick={() => {
              this.setState(
                {
                  permitList: [],
                  spacesFiles: {},
                  isValidating: false,
                  validationMsg: '',
                },
                this.context.clearAllInputValues(this.context.inputList) //A added this.context.inputList
              );
            }}
          >
            clear
          </Button>&nbsp;
          <Button
            variant='contained'
            onClick={() => this.addSettings(PREV)}
          >
            previous
          </Button>&nbsp;
          <Button
            variant='contained'
            onClick={() => this.addSettings(NEXT)}
          >
            next
          </Button>
        </div>
      </div>
    );
  }

  addSettings(direction) {
    const inputList = cloneDeep(this.context.inputList);
    const validationObject = this.context.checkRequiredInputs(inputList);
    if (direction === PREV) {
      this.props.prevStep();
    }
    else {
      if (validationObject.isValid) {
        const { spacesFiles } = this.state;
        const regularInputs = inputList.filter(input => !input.inputName.includes('~'));
        const permitInputs = inputList.filter(input => input.inputName.includes('~'));
        const limitInput = regularInputs.find(input => input.inputName === 'permitLimit');
        const limit = Number(limitInput.val);
        const permits = [];
        for (let i = 0; i < limit; ++i) {
          const specifigPermit = permitInputs.filter(input => input.inputName.includes(i));
          const permit = this.context.prepInputList(specifigPermit);
          permits.push(permit);
        }
        const settings = this.context.prepInputList(regularInputs);
        settings.permits = permits;
        const propertyObj = cloneDeep(this.props.propertyObj);
        propertyObj.Guest = settings;
        if (!isEmpty(spacesFiles)) {
          propertyObj.Guest.SpacesFiles = spacesFiles;
        }
        this.props.buildProperty(propertyObj);
        this.props.nextStep();
      }
      else {
        this.context.updateInputList(inputList);
        this.forceUpdate();
      }
    }
  }
  
  renderPermits() {
    const { permitList } = this.state;
    const activeGuestPermitLimit = activeGuestLimit();
    const limit = permitList.length;
    if (limit > 0) {
      return permitList.map((permit, index) => {
        const inputList = cloneDeep(this.context.inputList);
        const hourlyPermit = `hourlyPermits~${index}`;
        const hourlyInput = inputList.find(input => input.inputName === hourlyPermit);
        let isHourlyDisabled = false;
        if (hourlyInput && hourlyInput.val) {
          isHourlyDisabled = hourlyInput.val;
        }
        return (
          <div className={styles.Permits_container} key={index}>
            <div className={styles.Permits_header}>Permit ({index + 1} of {limit})</div>
            <div className={styles.Permits_permitType}>
              <DropdownInput
                inputName={`permitType~${index}`}
                isRequired={true}
                labelText='Permit Type'
                style={{ width: '300px' }}
                options={guestPermitTypes.map(permitType => ({
                  value: permitType.id,
                  display: permitType.name,
                }))}
                inputFocus={true}
                onSelection={() => this.forceUpdate()}
              />
            </div>
            <div className={styles.Permits_activeGuestLimit}>
              <DropdownInput
                inputName={`activeGuestLimit~${index}`}
                labelText='Active Permit Limit'
                isRequired={true}
                style={{ width: '300px' }}
                options={activeGuestPermitLimit}
              />
            </div>
            <div className={styles.Permits_consecutiveDays}>
              <DropdownInput
                inputName={`consecutiveDays~${index}`}
                labelText='Total Guest Consecutive Days'
                isRequired={true}
                style={{ width: '300px' }}
                options={days.map(day => ({
                  value: day,
                  display: day,
                }))}
              />
            </div>
            <div className={styles.Permits_monthlyGuestPermitLimit}>
              <DropdownInput
                inputName={`monthlyPermitLimit~${index}`}
                labelText='Monthly Permit Limit (days)'
                isRequired={true}
                style={{ width: '300px' }}
                options={days.map(day => ({
                  value: day,
                  display: day,
                }))}
              />
            </div>
            <div className={styles.Permits_assignmentMethod}>
              <DropdownInput
                inputName={`assignmentMethod~${index}`}
                labelText='Assignment Method'
                isRequired={true}
                style={{ width: '300px' }}
                options={guestAssignmentMethod.map(range => ({
                  value: range.id,
                  display: range.name,
                }))}
                onSelection={() => this.forceUpdate()}
              />
            </div>
            <div className={styles.Permits_dailyPermitCost}>
              <CurrencyAmountInput
                inputName={`dailyPermitCost~${index}`}
                isRequired={true}
                labelText='Daily Permit Cost (24 Hours)'
                autoComplete={'off'}
                style={{ width: '300px' }}
              />
            </div>
            


            <div className={styles.Permits_oneHourCost}>
              <CurrencyAmountInput
                inputName={`oneHourCost~${index}`}
                isRequired={true}
                labelText='1 Hour Cost'
                autoComplete={'off'}
                style={{ width: '300px' }}
                isDisabled={isHourlyDisabled}
              />
            </div>
            <div className={styles.Permits_guestNote}>
              <TextInput
                inputName={`guestNote~${index}`}
                isRequired={false}
                labelText='Guest Note'
                autoComplete={'off'}
                isMultiLine={true}
                maxLength={50}
                inputFullWidth
              />
            </div>
            <div className={styles.Permits_spacesUpload}>
              {this.renderSpacesUploadButton(permit, index)}
            </div>
          </div>
        );
      });
    }
  }

  renderSpacesUploadButton(permit, index) {
    const specificPermitType = this.context.inputList.find(input => input.inputName.includes('permitLimit'));
    const assignmentMethod = this.context.inputList.find(input => input.inputName.includes(`assignmentMethod~${index}`));
    if (specificPermitType?.val === undefined) {
      return null;
    }
    else if (permit.hasFiles) {
      return <label className={styles.hasFiles}>this permit has a spaces file</label>;
    }
    else if (assignmentMethod && assignmentMethod.val > 0) { // Selected Assignment Method must be "By Unit" to display parking spaces upload button
      return (
        <label className={styles.customFileUpload}>
          <input type='file' accept='text/csv' onChange={e => this.parseFile(e)} id={`parkingSpaces~${index}`} />
          <div style={{ display: 'flex' }}>
            <div style={{ alignSelf: 'center' }}><FileUploadIcon style={{ color: 'rgb(14,196,126)', fontSize: '30px' }} /></div>
            <div style={{ alignSelf: 'center' }}>Upload Parking Spaces File</div>
          </div>
        </label>
      );
    }
  }
  
  parseFile(e) {
    const { spacesFiles } = this.state;
    const fileName = e.target.id;
    const file = document.getElementById(fileName).files[0];
    const indexOfPermitType = Number(fileName.indexOf('~'));
    const permitTypeId = fileName.substring(indexOfPermitType + 1, fileName.length);
    const permitType = this.context.inputList.find(input => input.inputName.includes(`permitType~${permitTypeId}`));
    if (file.type.includes('csv') && permitType) {
      let hasType = false;
      for (const [key] of Object.entries(spacesFiles)) {
        hasType = permitType.val === key;
      }  
      if (!hasType) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          const rows = parseCSV(reader.result);
          spacesFiles[permitType.val] = rows;
          const permitList = cloneDeep(this.state.permitList);
          permitList[permitTypeId].hasFiles = true;
          this.setState({ spacesFiles, permitList });
        };
      }
      else {
        this.setState({ isValidating: true, validationMsg: 'Cannot have duplicate Permit Types!' });
      }
    }
    else {
      this.setState({ 
        isValidating: true,
        validationMsg: 'File type is required to be a CSV (comma seperated values) file AND cannot match existing type' 
      });
    }
  }

  fileUplodadValidation() {
    const { isValidating, validationMsg } = this.state;
    const actionButtons = [
      {
        label: 'OK',
        action: () => this.setState({ isValidating: false, validationMsg: '' }),
        buttonType: 'text',
      },
    ];

    return (
      <Modal
        actionButtons={actionButtons}
        fullScreen={false}
        maxWidth={'md'}
        modal={false}
        onCloseModal={() => this.setState({ isValidating: false, validationMsg: '' })}
        show={isValidating}
        title='File Upload Validation'
      >
        <div style={{ margin: '0 0%', height: '55px', paddingTop: '15px' }}>
          {validationMsg}
        </div>
      </Modal>
    );
  }

}

export default PropertyGuestSettingsGroupForm;