import React, { Component } from 'react';

import SmartFormProvider from 'utils/SmartForm/SmartFormProvider';
import RolesManageForm from 'view/private/Body/Staff/RolesManage/RolesManageForm/index';

import { LoadingOverlay } from '@timber-rider/parkingtree-client-common';

//import styles from 'view/private/Body/Staff/RolesManage/index.module.css';

export class RolesManage extends Component {

  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    return (
      <LoadingOverlay
        show={loading}
        width='100%'
      >
        <SmartFormProvider> 
          <RolesManageForm /> 
        </SmartFormProvider>
      </LoadingOverlay>
    );
  }
}

  
export default RolesManage;