import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit, cloneDeep, } from 'lodash';
import moment from 'moment';
import theme from 'utils/theme/parking_tree';

import { lighten, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';

import PriorityHigh from '@mui/icons-material/PriorityHigh';

const styles = ({
  indicator: {
    visibility: 'hidden',
  },
  taskQueueRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.8),
      '& #WorkQueue_priorityIcon': {
        visibility: 'visible',
        color: '#000',
      },
    },
  },
  tardyRow: {
    cursor: 'pointer',
    color: '#606060',
    backgroundColor: '#FCF3CF',
    '&:hover': {
      color: '#000',
      backgroundColor: '#F7DC6F',
      '& #WorkQueue_priorityIcon': {
        visibility: 'visible',
        color: '#000',
      },
    },
  },
  overdueRow: {
    cursor: 'pointer',
    color: '#606060',
    backgroundColor: '#E6B0AA',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#CD6155',
      '& #WorkQueue_priorityIcon': {
        visibility: 'visible',
        color: '#FFF',
      },
    },
  }
});

export class TaskQueueTableRow extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,

    // from TableRows
    CustomRowProps: PropTypes.shape({
      taskAction: PropTypes.func.isRequired,
    }).isRequired,
    dataFormat: PropTypes.func.isRequired,
    defaultRowClasses: PropTypes.object.isRequired,
    idKey: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
    RowMenu: PropTypes.elementType.isRequired, // React component prop
    tableActions: PropTypes.shape({
      updateTableState: PropTypes.func.isRequired,
    }).isRequired,
    tableData: PropTypes.shape({
      columns: PropTypes.array.isRequired,
      rowData: PropTypes.array.isRequired,
      selectEnabled: PropTypes.bool,
      statusOptions: PropTypes.array,
    }).isRequired,
    types: PropTypes.object.isRequired,
  };

  render() {
    const { classes, CustomRowProps: { taskAction }, dataFormat, idKey, tableData: { columns, }, types } = this.props;
    const row = cloneDeep(this.props.row);
    const rowType = this.getRowType(row);

    const rowColumns = columns.map(column => {
      // eslint-disable-next-line array-callback-return
      if (!column.show) return; // if column.show is false, do not load the column
      // eslint-disable-next-line array-callback-return
      if (column.key === 'placeHolderColumn') return;

      return (
        <td
          key={`${row[idKey]}_${column.key}`}
          onClick={() => taskAction(row)}
          style={{
            fontSize: 'medium',
            ...column.type === types.CURRENCY ? { textAlign: 'right', } : {},
            ...omit(column.customStyle, ['width'])
          }}
        >
          {column.format ? column.format(row[column.key], row) : dataFormat(row[column.key], column.type)}
        </td>
      );
    });

    rowColumns.unshift(
      <td 
        key='select'
        style={{ ...columns[0].customStyle, textAlign: 'right' }}
      >
        <div className={classes.indicator} id='WorkQueue_priorityIcon'>
          <Icon><PriorityHigh /></Icon>
        </div>
      </td>
    );
    
    return (
      <tr
        key={row[idKey]}
        className={rowType.row}
      >
        {rowColumns}
      </tr>
    );
  }

  /**
   * @param {string} task The task object with the taskType
   * 
   * @returns {string} the correct class name
   */
  getRowType = (task) => {
    const { classes } = this.props;

    if (task) {
      const taskDate = moment(new Date(task.DateModified));
      const curDate = moment(new Date());
      let delta = taskDate.diff(curDate, 'minutes');
      
      if (!isNaN(delta)) {
        delta = Math.abs(delta);
        const result = delta / task.Duration * 100;
        if (result < 35) { 
          return { row: classes.taskQueueRow }; 
        } // eslint-disable-line
        else if (result > 34 && result < 65) { 
          return { row: classes.tardyRow }; 
        } // eslint-disable-line
        else { 
          return { row: classes.overdueRow }; 
        } // eslint-disable-line
      }
      else {
        return { row: classes.taskQueueRow };
      }
    }
  };

}

export default withStyles(styles)(TaskQueueTableRow);